import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService, SentryLogger } from '@fcom/core/services';
import { mapError, mapErrorForSentry } from '@fcom/core/utils';

import {
  JoinFinnairPlusErrorResponseData,
  JoinFinnairPlusRequestData,
  JoinFinnairPlusResponseData,
  VerifyFinnairPlusResponseData,
} from '../../interfaces/join-finnair-plus.interface';

@Injectable({
  providedIn: 'root',
})
export class JoinService {
  public joinDialogOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sentryLogger: SentryLogger
  ) {}

  joinFinnairPlus(joinData: JoinFinnairPlusRequestData): Observable<JoinFinnairPlusResponseData> {
    const apiUrl = this.configService.cfg.loyaltyServices.legacy.loyaltyMemberUrl;
    const apiKey = this.configService.cfg.loyaltyServices.legacy.loyaltyMemberApiKey;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      }),
    };

    return this.http.post<JoinFinnairPlusResponseData>(apiUrl, joinData, httpOptions).pipe(
      catchError((error: unknown) => {
        this.sentryLogger.error('joinFinnairPlus error', {
          error: mapErrorForSentry(error),
        });
        return throwError(() => mapError<JoinFinnairPlusErrorResponseData>(error));
      })
    );
  }

  resendAccountVerificationEmail(memberNumber: string): Observable<VerifyFinnairPlusResponseData> {
    const apiUrl = this.configService.cfg.loyaltyServices.legacy.loyaltyMemberResendUrl;
    const apiKey = this.configService.cfg.loyaltyServices.legacy.loyaltyMemberApiKey;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      }),
    };

    return this.http.post<VerifyFinnairPlusResponseData>(apiUrl, memberNumber, httpOptions).pipe(
      catchError((error: unknown) => {
        this.sentryLogger.error('resendAccountVerificationEmail error', {
          error: mapErrorForSentry(error),
        });
        return throwError(() => error);
      })
    );
  }
}
