import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  scan,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { GlobalBookingTravelClass, LocationRouteCffService, StorageService } from '@fcom/core';
import { TripType } from '@fcom/core/interfaces';
import { convertStringDatesToDateObjects, isEmpty, isPresent, LocalDate, TzDate, unsubscribe } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';
import { IconButtonSize, IconButtonTheme } from '@fcom/ui-components';

import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { PreviousSearch } from '../../interfaces';
import { PREVIOUS_SEARCHES_KEY } from '../../constants';
import { BookingWidgetFlightService } from '../../services/booking-widget-flight.service';

@Component({
  selector: 'fin-previous-searches',
  templateUrl: './previous-searches.component.html',
  styleUrls: ['./previous-searches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviousSearchesComponent implements OnInit, OnDestroy {
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly TripType = TripType;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  isGlobalBookingWidget = false;

  @Input()
  enableNewSearchAutomatically = false;

  @Output()
  closeLocationModal = new EventEmitter<void>();

  @Output()
  focusOnSearchInput = new EventEmitter<void>();

  @Output()
  startSearch = new EventEmitter<void>();

  @ViewChildren('previousSearchItems') previousSearchItems: QueryList<ElementRef>;

  previousSearches$: Observable<PreviousSearch[]>;
  isMulticityListOnly$: Observable<boolean>;
  removePreviousSearchByCreationDate$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  private loadedPreviousSearches$: Observable<PreviousSearch[]>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private storageService: StorageService,
    private languageService: LanguageService,
    private locationRouteCffService: LocationRouteCffService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private bookingWidgetFlightService: BookingWidgetFlightService
  ) {}

  ngOnInit(): void {
    const loadedSearches = this.getPreviousSearches().filter(this.isPreviousSearchValid);

    this.loadedPreviousSearches$ = forkJoin(
      loadedSearches.map((search) =>
        forkJoin(
          search.flights.map((flight) =>
            combineLatest([
              this.getLang(search.locale) !== this.languageService.langKeyValue
                ? this.locationRouteCffService.bestGuessFor(
                    flight.origin?.locationCode,
                    this.languageService.localeValue
                  )
                : of(flight.origin),
              this.getLang(search.locale) !== this.languageService.langKeyValue
                ? this.locationRouteCffService.bestGuessFor(
                    flight.destination?.locationCode,
                    this.languageService.localeValue
                  )
                : of(flight.destination),
            ]).pipe(
              map(([origin, destination]) => ({
                ...flight,
                origin: {
                  ...flight.origin,
                  cityName: origin.cityName,
                },
                destination: {
                  ...flight.destination,
                  cityName: destination.cityName,
                },
              }))
            )
          )
        ).pipe(
          map((flights) => ({
            ...search,
            flights,
            locale: this.languageService.localeValue,
            tripType: search.tripType ?? search.travelType,
            travelClass: search.travelClass.toUpperCase() as GlobalBookingTravelClass,
          }))
        )
      )
    ).pipe(
      tap((previousSearches) => {
        if (previousSearches.length > 0) {
          this.bookingWidgetGtmService.trackElementEvent(
            'show-previous-searches',
            `previous-searches-${previousSearches.length}`
          );
        }
        this.persistPreviousSearches(previousSearches);
      }),
      finShare()
    );

    this.previousSearches$ = this.removePreviousSearchByCreationDate$.pipe(
      scan<number, number[]>((acc, creationDate) => [...acc, creationDate], []),
      switchMap((creationDateToBeRemoved) =>
        this.loadedPreviousSearches$.pipe(
          map((previousSearches) =>
            previousSearches.filter(({ creationDate }) => !creationDateToBeRemoved.includes(creationDate))
          )
        )
      ),
      finShare()
    );

    this.isMulticityListOnly$ = this.previousSearches$.pipe(
      map((searches) => searches.every((search) => search.tripType === TripType.MULTICITY))
    );

    this.subscriptions.add(
      this.previousSearches$.subscribe((previousSearches) => {
        this.persistPreviousSearches(previousSearches);
      })
    );

    this.subscriptions.add(
      this.removePreviousSearchByCreationDate$
        .pipe(
          switchMap((creationDateToBeRemoved) =>
            this.loadedPreviousSearches$.pipe(
              map((previousSearches) =>
                previousSearches.find(({ creationDate }) => creationDateToBeRemoved === creationDate)
              )
            )
          ),
          filter((previousSearch) => isPresent(previousSearch))
        )
        .subscribe((previousSearch) => {
          this.bookingWidgetGtmService.trackElementEvent(...this.getPreviousSearchContext('remove', previousSearch));
        })
    );

    this.subscriptions.add(
      this.removePreviousSearchByCreationDate$
        .pipe(
          filter(Boolean),
          switchMap(() => this.previousSearches$)
        )
        .subscribe((previousSearches) => {
          this.movePreviousSearchFocus(previousSearches);
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  setSearch(previousSearch: PreviousSearch): void {
    this.bookingWidgetFlightService.setPreviousFlightSelection(previousSearch, this.isGlobalBookingWidget);
    this.bookingWidgetGtmService.trackElementEvent(...this.getPreviousSearchContext('select', previousSearch));

    if (this.enableNewSearchAutomatically) {
      this.startSearch.emit();
    }

    this.closeLocationModal.emit();
  }

  private getPreviousSearches = (): PreviousSearch[] => {
    const previousSearchesStorage = this.storageService.LOCAL.get(PREVIOUS_SEARCHES_KEY);

    try {
      return this.parsePreviousSearches(JSON.parse(previousSearchesStorage) ?? []);
    } catch {
      return [];
    }
  };

  /**
   * Rewrites the old format of previous searches so that multi-city can be handled.
   *
   * Also parse some date strings and converts them into LocalDate.
   */
  private parsePreviousSearches(previousSearches: PreviousSearch[]): PreviousSearch[] {
    return convertStringDatesToDateObjects(previousSearches).map((previousSearch) => {
      const search = { ...previousSearch };

      // Convert old format previousSearches to use the new flights array
      if (search.origin || search.destination || search.travelDates) {
        search.flights = [
          {
            origin: search.origin,
            destination: search.destination,
            departureDate: new LocalDate(search.travelDates.departureDate),
          },
        ];

        if (search.tripType === TripType.RETURN) {
          search.flights.push({
            origin: search.destination,
            destination: search.origin,
            departureDate: new LocalDate(search.travelDates.returnDate),
          });

          delete search.origin;
          delete search.destination;
          delete search.travelDates;
        }
      }

      return search;
    });
  }

  private persistPreviousSearches = (previousSearches: PreviousSearch[]): void => {
    if (isEmpty(previousSearches)) {
      this.storageService.LOCAL.remove(PREVIOUS_SEARCHES_KEY);
    } else {
      this.storageService.LOCAL.set(PREVIOUS_SEARCHES_KEY, JSON.stringify(previousSearches));
    }
  };

  private isPreviousSearchValid = (search: PreviousSearch): boolean => {
    if (!isPresent(search)) {
      return false;
    }

    const departureIsNotInPast = search.flights[0].departureDate.gte(LocalDate.now());
    const creationIsBetween14Days =
      Math.floor((TzDate.now().millis - search.creationDate) / (1000 * 60 * 60 * 24)) < 14;

    return departureIsNotInPast && creationIsBetween14Days;
  };

  private getLang = (locale: string): string | undefined => locale?.substring(0, 2);

  private getPreviousSearchContext = (type: string, previousSearch: PreviousSearch): [string, string] => {
    const { flights } = previousSearch;
    const locations = `${flights[0].origin.locationCode} - ${flights[0].destination.locationCode}`;
    const dates = `DEPARTURE: ${flights[0].departureDate}${
      isPresent(flights[1]?.departureDate) ? `, RETURN: ${flights[1]?.departureDate}` : ''
    }`;
    const id = `${type}-previous-search`;
    const state = `${locations}, ${dates}`;

    return [id, state];
  };

  private movePreviousSearchFocus = (previousSearches: PreviousSearch[]): void => {
    if (isEmpty(previousSearches)) {
      this.focusOnSearchInput.emit();
      return;
    }
    requestAnimationFrame(() => {
      this.previousSearchItems?.first?.nativeElement?.focus();
    });
  };
}
