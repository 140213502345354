import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, type UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConfigService, mapErrorForSentry, SentryLogger, WindowRef, type AppState } from '@fcom/core';
import { EMAIL_REGEX, NAME_PATTERN, PNR_PATTERN } from '@fcom/core/utils';
import { type Profile } from '@fcom/core-api/login';
import { ButtonMode, ButtonSize, ButtonType, TextInputType } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';

import {
  PrechatFields,
  SplitTrafficChatFields,
  SplitTrafficAdditionalChatFields,
  AgentforceChatConfig,
} from '../../interfaces';
import { SalesforceChatService } from '../../services/salesforce-chat.service';
import { SalesforceChatConfigService } from '../../services/salesforce-chat-config.service';

export enum SalesforceChatFormControl {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  SUBJECT = 'subject',
  BOOKING_REFERENCE = 'bookingReference',
}

export interface SalesforceChatForm {
  [SalesforceChatFormControl.FIRST_NAME]: string;
  [SalesforceChatFormControl.LAST_NAME]: string;
  [SalesforceChatFormControl.EMAIL]: string;
  [SalesforceChatFormControl.SUBJECT]: string;
  [SalesforceChatFormControl.BOOKING_REFERENCE]: string;
}

@Component({
  selector: 'fin-chat-user-form',
  templateUrl: './chat-user-form.component.html',
  styleUrl: './chat-user-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUserFormComponent implements OnInit, OnDestroy {
  readonly ButtonType = ButtonType;
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly SalesforceChatFormControl = SalesforceChatFormControl;
  readonly textareaLimit50 = 50;
  readonly TextInputType = TextInputType;

  reactiveForm: UntypedFormGroup;
  hiddenFields: SplitTrafficAdditionalChatFields;

  private subscriptions: Subscription = new Subscription();

  embeddedSvc: {
    settings: { [key: string]: unknown };
    // eslint-disable-next-line @typescript-eslint/ban-types
    init: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    bootstrapEmbeddedService: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    addEventHandler: Function;
  };

  embeddedSvcBootstrap: {
    settings: { [key: string]: unknown };
    // eslint-disable-next-line @typescript-eslint/ban-types
    init: Function;
    prechatAPI: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      setHiddenPrechatFields?: Function;
    };
    utilAPI: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      launchChat: Function;
      // eslint-disable-next-line @typescript-eslint/ban-types
      removeAllComponents: Function;
    };
    userVerificationAPI: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      clearSession: Function;
    };
  };

  chatHasBeenTriggered = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  agentforceConfig?: AgentforceChatConfig;

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private sfChatConfigService: SalesforceChatConfigService,
    private sfChatService: SalesforceChatService,
    private languageService: LanguageService,
    private windowRef: WindowRef,
    private sentryLogger: SentryLogger,
    private configService: ConfigService
  ) {
    this.agentforceConfig = this.configService.cfg.agentforceChat;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store$.pipe(profileOrUndefinedIfNotLoggedIn(), take(1)).subscribe((profile: Profile): void => {
        this.createForm(profile);
        this.hiddenFields = this.setHiddenFields(profile);
      })
    );

    // Hack to refesh the chat when the language is changed to non EN site.
    this.subscriptions.add(
      this.languageService.lang.subscribe((lang): void => {
        if (!lang.split('-').includes('en')) {
          window.location.reload();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createForm(profile?: Profile): void {
    this.reactiveForm = this.fb.group({
      [SalesforceChatFormControl.FIRST_NAME]: [
        profile?.firstname || '',
        [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(this.textareaLimit50)],
      ],
      [SalesforceChatFormControl.LAST_NAME]: [
        profile?.lastname || '',
        [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(this.textareaLimit50)],
      ],
      [SalesforceChatFormControl.EMAIL]: [profile?.email || '', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      [SalesforceChatFormControl.SUBJECT]: ['', [Validators.required, Validators.maxLength(32)]],
      [SalesforceChatFormControl.BOOKING_REFERENCE]: ['', [Validators.pattern(PNR_PATTERN), Validators.maxLength(6)]],
    });
  }

  setHiddenFields(profile: Profile): SplitTrafficAdditionalChatFields {
    if (profile) {
      return {
        Finnair_Plus_Number_c: profile.memberNumber,
        Case_Member_Tier_c: profile.tier,
        AuthenticatedUser: 'true', // Must be a string
      };
    }
    return {
      AuthenticatedUser: 'false', // Must be a string
    };
  }

  liveAgentChatData = (baseData: SplitTrafficChatFields, bookingRef: string): SplitTrafficChatFields => {
    return {
      ...baseData,
      Booking_Reference: bookingRef,
    };
  };

  webMessagingChatData = (baseData: SplitTrafficChatFields): SplitTrafficChatFields => {
    return {
      ...baseData,
      ...this.hiddenFields,
      WebSite_Country: this.languageService.ddsLocaleValue.countrySite.toLowerCase(),
      WebSite_Language: this.languageService.langKeyValue,
    };
  };

  onSubmit(): void {
    const formData: PrechatFields = this.reactiveForm.value;
    const baseData: SplitTrafficChatFields = {
      FirstName_c: formData.firstName,
      LastName_c: formData.lastName,
      Email_c: formData.email,
      Subject_c: formData.subject,
    };
    const enableLiveAgentChat = Boolean(formData.bookingReference);

    if (!this.reactiveForm.valid) {
      this.reactiveForm.markAllAsTouched();
      this.reactiveForm.updateValueAndValidity();

      return;
    }

    // Direct user with a booking reference to liveagent chat.
    if (enableLiveAgentChat) {
      this.sfChatService
        .load(this.sfChatConfigService.scriptSrcURL)
        .then((): void => {
          this.embeddedSvc = this.windowRef.nativeWindow['embedded_svc'];
        })
        .catch((error: unknown): void => {
          this.sentryLogger.error('Error loading LiveAgenct chat:', { error: mapErrorForSentry(error) });
        })
        .finally((): void => {
          const liveagentData: SplitTrafficChatFields = this.liveAgentChatData(baseData, formData.bookingReference);
          this.embeddedSvc.settings.prepopulatedPrechatFields = liveagentData;
          this.embeddedSvc.settings.displayHelpButton = true;
          this.embeddedSvc.settings.language = this.languageService.langKeyValue;
        });

      return;
    }

    // Direct user without a booking reference to web messaging chat.
    this.sfChatService
      .load(this.agentforceConfig.chatScriptSrcURL)
      .then((): void => {
        this.embeddedSvcBootstrap = this.windowRef.nativeWindow['embeddedservice_bootstrap'];
      })
      .catch((error: unknown): void => {
        this.sentryLogger.error('Error loading Messaging for Web chat:', { error: mapErrorForSentry(error) });
      })
      .finally((): void => {
        this.webMessagingChat(this.webMessagingChatData(baseData));
      });

    this.loading$.next(true);

    if (this.chatHasBeenTriggered) {
      // Allows chat to launch on re-submission after user has ended the conversation
      this.embeddedSvcBootstrap.utilAPI.launchChat();
    }
  }

  webMessagingChat(formData: SplitTrafficChatFields): void {
    window.addEventListener('onEmbeddedMessagingReady', (): void => {
      this.embeddedSvcBootstrap.prechatAPI.setHiddenPrechatFields(formData);
    });

    window.addEventListener('onEmbeddedMessagingButtonCreated', (): void => {
      // Check if the chat has been triggered before.
      // Otherwise the chat will launch everytime the user ends the chat session. (Chat button is created again at closing)
      if (!this.chatHasBeenTriggered) {
        this.chatHasBeenTriggered = true;
        this.embeddedSvcBootstrap.utilAPI.launchChat();
      }
    });

    window.addEventListener('onEmbeddedMessagingConversationClosed', (): void => {
      // Clear the user session when it has ended.
      this.embeddedSvcBootstrap.userVerificationAPI.clearSession(true);
      this.loading$.next(false);
    });

    if (this.embeddedSvcBootstrap) {
      this.embeddedSvcBootstrap.settings.shouldShowParticipantChgEvntInConvHist = false; // removing additional 'joining' and 'leaving' messages
      this.embeddedSvcBootstrap.settings.devMode = false;
      this.embeddedSvcBootstrap.settings.language = 'en';
      this.embeddedSvcBootstrap.settings.hideChatButtonOnLoad = true;

      this.embeddedSvcBootstrap.init(
        this.agentforceConfig.queueConfigs.DEFAULT.deploymentId,
        this.agentforceConfig.queueConfigs.DEFAULT.buttonId,
        this.agentforceConfig.queueConfigs.DEFAULT.agentUrl,
        {
          scrt2URL: this.agentforceConfig.scrt2URL,
        }
      );
    }
  }
}
