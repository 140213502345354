<div class="product-card white-bg grow fill flex flex--column relative">
  @if (useNewProductCardLayout) {
    <fcom-image
      *ngIf="image"
      class="picture block rounded-top-large border-top border-left border-right border"
      [ratio]="'21x9'"
      [imageData]="image"
    ></fcom-image>
    <div class="flex-1 flex flex--column rounded-bottom-large border-bottom border-left border-right border">
      <div class="flex-1 flex flex--row flex--space-between pr-small-x ps-medium-y">
        <div class="flex-1 flex flex--column">
          <h2 class="nordic-blue-900-text font-heading-4">{{ title }}</h2>

          @if (!countText) {
            @if (lowestPrice) {
              <div class="flex flex--middle">
                <span class="font-body-2" data-testid="product-card-starting-from-label">{{
                  'pricesStartingFrom' | finLocalization
                }}</span>
                @if (originalPrice) {
                  <span class="font-body-2 strike-through ps-xxsmall-l" data-testid="product-card-original-price">{{
                    originalPrice
                  }}</span>
                }
              </div>
              <span
                [ngClass]="{ 'heather-700-text': originalPrice }"
                class="font-heading-5"
                data-testid="product-card-price"
                >{{ lowestPrice }}</span
              >
            }

            @if (productTagText && productTagIcon) {
              <fcom-tag data-testid="product-card-product-tag" [theme]="productTagTheme">
                <fcom-icon class="icon-small" [name]="productTagIcon"></fcom-icon>
                <span class="ms-xxsmall-l">{{ productTagText }}</span>
              </fcom-tag>
            }
          }
        </div>

        <fcom-button
          class="block ps-xlarge-t flex--self-end"
          [mode]="ButtonMode.INLINE"
          [loading]="loading"
          [theme]="buttonTheme"
          [text]="buttonText"
          [icon]="{ name: buttonIcon, position: IconPosition.RIGHT }"
          [title]="buttonAriaText"
          [aria]="{ label: buttonAriaText, role: 'button', loadingLabel: 'actions.loading' | finLocalization }"
          [focus$]="focusOnBtn$"
          (clickBtn)="emitProductCategory($event)"
        >
        </fcom-button>
      </div>

      @if (countText) {
        <div class="border-top font-body-1 bold-type ps-xsmall-y pr-small-x" data-testid="product-card-count-text">
          {{ countText }}: {{ price }}
        </div>
      }

      <fcom-notification
        *ngIf="notificationText"
        [theme]="notificationTheme"
        [layout]="NotificationLayout.EDGE_TO_EDGE"
      >
        {{ notificationText }}
      </fcom-notification>
    </div>
  } @else {
    <fcom-tag class="absolute ms-xsmall-t ms-xsmall-r z2" *ngIf="badgeText" [theme]="TagTheme.POPULAR">
      {{ badgeText }}
    </fcom-tag>

    <fcom-image
      *ngIf="image"
      class="picture block rounded-top-large border-top border-left border-right border"
      [ratio]="'21x9'"
      [imageData]="image"
    ></fcom-image>

    <div
      class="flex-1 pr-small-x ps-medium-y flex flex--column rounded-bottom-large border-bottom border-left border-right border"
    >
      <h2 class="nordic-blue-900-text font-heading-4">{{ title }}</h2>

      @if (lowestPrice) {
        <div class="flex flex--middle">
          <span class="font-body-2 ps-xxsmall-r" data-testid="product-card-starting-from-label">{{
            'pricesStartingFrom' | finLocalization
          }}</span>
          <span
            [ngClass]="{
              'font-heading-5': !originalPrice,
              'strike-through': originalPrice,
              'font-body-2': originalPrice,
            }"
            data-testid="product-card-starting-from-price"
            >{{ originalPrice ? originalPrice : lowestPrice }}</span
          >
          @if (originalPrice) {
            <span class="font-heading-5 heather-700-text ps-xxsmall-l" data-testid="product-card-discounted-price">{{
              lowestPrice
            }}</span>
          }
        </div>
      }

      @if (productTagText && productTagIcon) {
        <fcom-tag data-testid="product-card-product-tag" [theme]="productTagTheme">
          <fcom-icon class="icon-small" [name]="productTagIcon"></fcom-icon>
          <span class="ms-xxsmall-l">{{ productTagText }}</span>
        </fcom-tag>
      }

      <p *ngIf="detailText" class="ps-xsmall-t ps-small-b font-body-1 flex-1">{{ detailText }}</p>

      <ng-container *ngIf="statusText || purchasedText">
        <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />

        <div class="ps-small-y align-right">
          <div class="nordic-blue-900-text font-body-1" *ngIf="purchasedText">
            {{ purchasedText }}
          </div>
          <div
            class="font-body-1"
            [ngClass]="{
              'medium-type': hasAddedItems,
              'nordic-nordic-blue-900-100-text': hasAddedItems,
              'nordic-blue-900-text': !hasAddedItems,
            }"
          >
            {{ statusText }}
          </div>
          <div *ngIf="price" class="font-body-1 nordic-blue-900-text">{{ price }}</div>
        </div>

        <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />
      </ng-container>

      <div class="align-center">
        <fcom-button
          *ngIf="!hideButton"
          class="block"
          [ngClass]="{ 'ps-small-y': !hideViewButton, 'ps-small-t': hideViewButton }"
          [mode]="ButtonMode.FILL"
          [appearDisabled]="loading"
          [loading]="loading"
          [theme]="buttonTheme"
          [text]="buttonText"
          [icon]="{ name: buttonIcon, position: IconPosition.RIGHT }"
          [title]="buttonAriaText"
          [aria]="{ label: buttonAriaText, role: 'button', loadingLabel: 'actions.loading' | finLocalization }"
          [focus$]="focusOnBtn$"
          (clickBtn)="emitProductCategory($event)"
        >
        </fcom-button>

        <fcom-notification *ngIf="notificationText" class="block ps-small-y ps-small-t" [theme]="notificationTheme">
          {{ notificationText }}
        </fcom-notification>

        <button
          #viewSummaryBtn
          class="view-details nordic-blue-900-text underline font-body-1"
          *ngIf="!hideViewButton"
          (click)="emitViewDetails($event)"
          [attr.aria-label]="viewSummaryText + ' ' + title"
        >
          {{ viewSummaryText }}
        </button>
      </div>
    </div>
  }
</div>
