import { TripType } from '@fcom/core/interfaces';
import { PopoverOptions, PopoverPosition } from '@fcom/ui-components';

import { NotificationWarning } from './interfaces';

export const PREVIOUS_SEARCHES_KEY = 'previousSearches';
export const ANIMATION_DURATION = 250;
export const AVAILABLE_TRIP_TYPES: TripType[] = Object.values(TripType);
export const GLOBAL_BOOKING_WIDGET_EXPANDER_KEY = 'global-booking-widget-expander';
export const BOOKING_WIDGET_CONTEXT = 'booking-widget';
export const BOOKING_WIDGET_PROMO = 'booking-widget-promo';

export const defaultWidgetPopoverOptions: PopoverOptions = {
  context: BOOKING_WIDGET_CONTEXT,
  popoverPosition: PopoverPosition.BOTTOM,
  roundedCorners: true,
  showArrowCaret: false,
  zIndex: 4,
  useFixedPosition: true,
  showLeftBorder: false,
  hasPadding: false,
  showFullscreenOnBreakpoint: 'tablet_down',
  shadow: true,
};

export const warningNotificationPriorityOrder = {
  [NotificationWarning.NO_FLIGHTS]: 0,
  [NotificationWarning.NO_AWARD_FLIGHTS]: 1,
  [NotificationWarning.GENERAL]: 2,
  [NotificationWarning.SEASONAL_ROUTE]: 3,
};
