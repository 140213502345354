import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DirectiveModule } from '@fcom/common/directives';
import { ButtonModule, IconsModule, LoaderModule, TrapFocusModule, UiComponentsModule } from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { ComponentsModule, FragmentService } from '@fcom/common';
import { GtmModule } from '@fcom/common/gtm';

import { PreChatComponent } from './components/pre-chat/pre-chat.component';
import { SalesforceChatService } from './services/salesforce-chat.service';
import { SalesforceChatConfigService } from './services/salesforce-chat-config.service';
import { ChatComponent } from './components/chat/chat.component';
import { ChatGlobalVisibilityComponent } from './components/chat-global-visibility/chat-global-visibility.component';
import { ChatUserFormComponent } from './components/chat-user-form/chat-user-form.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    UiTranslateModule,
    ButtonModule,
    IconsModule,
    TrapFocusModule,
    LoaderModule,
    GtmModule,
    ReactiveFormsModule,
    UiComponentsModule,
  ],
  declarations: [ChatComponent, PreChatComponent, ChatGlobalVisibilityComponent, ChatUserFormComponent],
  exports: [ChatComponent, ChatGlobalVisibilityComponent],
  providers: [SalesforceChatService, SalesforceChatConfigService, FragmentService],
})
export class ChatModule {}
