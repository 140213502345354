export enum TagTheme {
  DEFAULT = 'default',
  ACTIVE = 'active',
  WHITE = 'white',
  SUSTAINABILITY = 'sustainability',
  SUCCESS = 'success',
  WARNING = 'warning',
  ALERT = 'alert',
  POPULAR = 'popular',
}

export enum TagRoundingStyle {
  DEFAULT = 'default',
  CHIP = 'chip',
  ONLY_BOTTOM_LEFT = 'only-bottom-left',
}
