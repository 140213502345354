import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { NotificationTheme } from '@fcom/ui-components';
import { StepperStep } from '@fcom/ui-components/components/stepper/interfaces';
import { StepperTheme } from '@fcom/ui-components/components/stepper/enums';
import { findScrollContainer } from '@fcom/core';

@Component({
  selector: 'fin-login-form-container',
  templateUrl: './login-form-container.component.html',
  styleUrls: ['./login-form-container.component.scss'],
})
export class LoginFormContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly StepperTheme = StepperTheme;

  readonly bottomMargin = 20;

  @ViewChild('dialogTitle') dialogTitle: ElementRef;
  @ViewChild('loginContainer') loginContainer: ElementRef;

  @Input()
  fullScreen = false;
  @Input()
  invertHeaderColors = false;

  /**
   * A boolean flag indicating whether to use a compact representation
   * which removes some paddings and margins
   */
  @Input()
  compact = false;
  @Input()
  icon: SvgLibraryIcon;
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  error: string;
  @Input()
  stepperSteps$: Observable<StepperStep[]>;
  @Input()
  stepperActiveStep$: Observable<number>;
  @Input()
  overlay = false;
  @Input()
  showLeftIcon = false;

  @Output()
  stepperClicked: EventEmitter<StepperStep> = new EventEmitter<StepperStep>();

  @Output()
  returnClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output()
  closeClicked: EventEmitter<Event> = new EventEmitter<Event>();

  availableHeight: number;

  constructor(
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.availableHeight = this.document.documentElement.clientHeight;
    this.renderer.addClass(this.document.body, 'modal-open-laptop-down');
    this.renderer.addClass(
      findScrollContainer(this.elementRef.nativeElement) || this.document.documentElement,
      'ios-scroll-touch'
    );

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'modal-open-laptop-down');
    this.renderer.removeClass(
      findScrollContainer(this.elementRef.nativeElement) || this.document.documentElement,
      'ios-scroll-touch'
    );
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.dialogTitle.nativeElement.focus();
    const containerYPos = this.loginContainer.nativeElement.getBoundingClientRect().top;
    this.availableHeight = this.availableHeight - containerYPos;
  }
}
