<div class="notification block" [ngClass]="notificationStyle">
  <div
    *ngIf="layout === NotificationLayout.EDGE_TO_EDGE && !excludeColumns; else wrappedContentTpl"
    class="grid-max-width grid-col-12"
  >
    <div class="col-1-12">
      <ng-container *ngTemplateOutlet="wrappedContentTpl"></ng-container>
    </div>
  </div>
</div>

<ng-template #wrappedContentTpl>
  <div
    class="flex flex--start"
    [ngClass]="{
      'ps-medium': !this.compact,
      'ps-xsmall flex--middle': this.compact,
    }"
  >
    <fcom-icon
      *ngIf="displayedIcon"
      class="message-type ms-xsmall-r flex--nogrow"
      [ngClass]="{ 'icon-small': compact, 'icon-medium': !compact }"
      [category]="iconCategory"
      [name]="displayedIcon"
    ></fcom-icon>

    <div
      class="flex flex-1"
      [ngClass]="{
        'flex--column row-gap': !horizontal,
        'font-body-1': !compact,
        'font-body-2': compact,
      }"
    >
      <div class="ps-xsmall-r flex--self-start">
        <span *ngIf="title" class="bold-type block ms-xsmall-b">{{ title }}</span>
        <ng-content></ng-content>
      </div>
      <ng-container *ngIf="linkText">
        <div *ngIf="linkUrl">
          <fcom-link
            class="ms-xsmall-r flex--self-start"
            [href]="linkUrl"
            [openInNewTab]="linkOpenInNewWindow"
            [external]="linkOpenInNewWindow"
            (beforeNavigation)="linkClick.emit($event)"
            >{{ linkText }}</fcom-link
          >

          <fcom-link
            *ngIf="secondaryLinkUrl && secondaryLinkText"
            class="ms-xsmall-r flex--self-start"
            [href]="secondaryLinkUrl"
            [openInNewTab]="secondaryLinkOpenInNewWindow"
            [external]="secondaryLinkOpenInNewWindow"
            (beforeNavigation)="secondaryLinkClick.emit($event)"
            >{{ secondaryLinkText }}</fcom-link
          >
        </div>

        <div *ngIf="!linkUrl" [ngClass]="{ 'flex--self-end': buttonStyle !== NotificationButtonStyle.LINK }">
          <ng-container
            *ngTemplateOutlet="buttonStyle === NotificationButtonStyle.LINK ? linkButtons : ctaButtons"
          ></ng-container>
        </div>
      </ng-container>
    </div>
    <fcom-loader [loaderType]="LoaderTheme.INLINE_TEXT" *ngIf="isLoading"></fcom-loader>
    <div class="close-button-wrapper" *ngIf="isClosable && !isLoading">
      <fcom-icon-button
        [icon]="closeIcon"
        [aria]="{ label: i18n.closeAriaLabel | finLocalization }"
        [size]="IconButtonSize.SMALL"
        [theme]="
          theme === NotificationTheme.CAMPAIGN
            ? IconButtonTheme.DARK_TERTIARY_GHOST
            : IconButtonTheme.LIGHT_TERTIARY_GHOST
        "
        (clickBtn)="closeClick.emit($event)"
        data-testid="close-button"
      ></fcom-icon-button>
    </div>
  </div>
</ng-template>

<ng-template #ctaButtons>
  <fcom-button
    *ngIf="secondaryLinkText && !secondaryLinkUrl"
    [text]="secondaryLinkText"
    [theme]="theme === NotificationTheme.CAMPAIGN ? ButtonTheme.DARK_TERTIARY_GHOST : ButtonTheme.LIGHT_TERTIARY_GHOST"
    (click)="secondaryLinkClick.emit($event)"
  >
  </fcom-button>
  <fcom-button
    class="ms-small-l"
    [text]="linkText"
    [theme]="
      theme === NotificationTheme.CAMPAIGN
        ? ButtonTheme.DARK_PRIMARY_NORDIC_BLUE
        : ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE
    "
    (click)="linkClick.emit($event)"
  >
  </fcom-button>
</ng-template>

<ng-template #linkButtons>
  <fcom-link
    class="ms-xsmall-r flex--self-start"
    *ngIf="secondaryLinkText"
    (afterNavigation)="secondaryLinkClick.emit($event)"
  >
    {{ secondaryLinkText }}
  </fcom-link>
  <fcom-link class="ms-xsmall-r flex--self-start" (afterNavigation)="linkClick.emit($event)">
    {{ linkText }}
  </fcom-link>
</ng-template>
