import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BookingAppState, SelectionPhase } from '@fcom/common/interfaces/booking';
import { BoundType } from '@fcom/dapi/interfaces';
import { ModalButtons, TabLayoutType } from '@fcom/ui-components';
import { BookingService } from '@fcom/booking/services';
import { FinnairBoundItem, FinnairCart } from '@fcom/dapi/api/models';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { GtmService } from '@fcom/common/gtm';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { cartData } from '@fcom/booking/store/selectors/cart.selector';
import { NativeBridgeService } from '@fcom/core';
import { TripType } from '@fcom/core/interfaces';
import { LanguageSelectorContext } from '@fcom/common/components/language-selector/language-selector.component';

import { getTripTypeFromBounds } from '../../../utils/common-booking.utils';

interface TicketSummaryModalState {
  open: boolean;
  bound: FinnairBoundItem;
  boundType: BoundType;
}

interface BoundSummary {
  bound: FinnairBoundItem;
  departureCity: string;
  arrivalCity: string;
  boundType: BoundType;
}

@Component({
  selector: 'fin-pax-flight-review-summary',
  templateUrl: './pax-flight-review-summary.component.html',
})
export class PaxFlightReviewSummaryComponent implements OnInit {
  public readonly BoundType = BoundType;
  readonly SelectionPhase = SelectionPhase;
  readonly LANGUAGE_SELECTOR_CONTEXT = LanguageSelectorContext.BOOKING;

  readonly selectionPhase$: BehaviorSubject<SelectionPhase> = new BehaviorSubject<SelectionPhase>(
    SelectionPhase.OUTBOUND
  );

  ModalButtons = ModalButtons;
  TabLayoutType = TabLayoutType;

  currentOffer$: Observable<FinnairCart>;

  ticketSummaryModal: TicketSummaryModalState = {
    open: false,
    bound: null,
    boundType: null,
  };
  enableNewLanguageSelector: boolean;
  boundSummary$: Observable<BoundSummary[]>;

  constructor(
    private bookingService: BookingService,
    private store$: Store<BookingAppState>,
    private gtmService: GtmService,
    private nativeBridgeService: NativeBridgeService
  ) {}

  ngOnInit(): void {
    this.currentOffer$ = this.store$.pipe(cartData());

    this.enableNewLanguageSelector = !this.nativeBridgeService.isInsideNativeWebView;

    this.boundSummary$ = this.currentOffer$.pipe(
      map((cart) => {
        const tripType = getTripTypeFromBounds(cart.bounds);

        return cart.bounds.map((bound, i) => {
          const departureCity = cart.locations[bound.departure.locationCode]?.cityName;
          const arrivalCity = cart.locations[bound.arrival.locationCode]?.cityName;

          return {
            bound,
            departureCity: departureCity || bound.departure.locationCode,
            arrivalCity: arrivalCity || bound.arrival.locationCode,
            boundType: this.getBoundTypeFromBound(tripType, i),
          };
        });
      })
    );
  }

  getBoundTypeFromBound(tripType: TripType, index: number): BoundType {
    if (tripType === TripType.ONEWAY) {
      return BoundType.outbound;
    }

    if (tripType === TripType.RETURN) {
      return index === 0 ? BoundType.outbound : BoundType.inbound;
    }

    return BoundType.multi;
  }

  showTicketSummaryModal(boundSummary: BoundSummary): void {
    this.ticketSummaryModal = {
      open: true,
      bound: boundSummary.bound,
      boundType: boundSummary.boundType,
    };

    this.gtmService.trackElement(
      'pax-details-flight-review::view-flight-details',
      GaContext.PAX_DETAILS_FLIGHT_REVIEW,
      ElementTypes.LINK,
      undefined,
      ElementActions.CLICK
    );
  }

  closeModals(): void {
    this.ticketSummaryModal = {
      open: false,
      bound: null,
      boundType: null,
    };
  }

  activateSelectionPhase(selectionPhase: SelectionPhase, locale?: string): void {
    this.selectionPhase$.next(selectionPhase);
    this.returnToFlightSelection(locale);
  }

  returnToFlightSelection(locale?: string): void {
    this.bookingService.setStep({ step: BOOKING_STEPS.FLIGHT_SELECTION, locale });
  }
}
