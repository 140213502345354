import { Injectable } from '@angular/core';

import { iif, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ConfigService } from '@fcom/core/services';
import {
  AirCalendarList,
  Cabin,
  FinnairAirBoundsRequest,
  FinnairAirBoundsResponse,
  FinnairRequestItineraryItem,
  FinnairSearchParameters,
  OfferList,
  StatusCode,
} from '@fcom/dapi/api/models';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { FlightSearchParams, PriceCalendarService, PriceCalendarWithPricesParams } from '@fcom/common';
import { OffersService } from '@fcom/dapi/api/services';
import { isPresent } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';
import { BookingAppState } from '@fcom/common/interfaces/booking';

import { notificationWarnings } from '../store';
import { NotificationWarning, SeasonalNotificationData, WarningData } from '../interfaces';
import { getBookingWidgetCabinClass } from '../utils/utils';
import { BookingWidgetActions } from '../store/actions';

@Injectable()
export class BookingWidgetCalendarService {
  static NUMBER_OF_RETRIES = 2;

  constructor(
    private store$: Store<BookingAppState>,
    private languageService: LanguageService,
    private configService: ConfigService,
    private offersService: OffersService,
    private priceCalendarService: PriceCalendarService
  ) {}

  getAirCalendar(params: FlightSearchParams): Observable<AirCalendarList> {
    const airCalendarRequest = this.createOffersRequest(params);
    return this.offersService.findAirCalendar1(this.configService.cfg.offersUrl, { body: airCalendarRequest }).pipe(
      switchMap((airCalendarList: AirCalendarList) => {
        if (isPresent(airCalendarList.airCalendars)) {
          this.store$.dispatch(BookingWidgetActions.setAirCalendarPrices({ prices: airCalendarList }));
          return of(airCalendarList);
        } else if (params.isAward) {
          const priceCalendarParams: PriceCalendarWithPricesParams = {
            origin: params.flights[0]?.origin.locationCode,
            destination: params.flights[0]?.destination.locationCode,
            tripType: params.tripType,
            travelClass: params.travelClass,
            paxAmount: params.paxAmount,
          };
          return this.priceCalendarService
            .getPricesForFollowingMonth(priceCalendarParams, params.flights[0]?.departureDate)
            .pipe(
              map((prices) => {
                this.setNotificationWarning(
                  Object.entries(prices || {}).length === 0
                    ? NotificationWarning.NO_FLIGHTS
                    : NotificationWarning.NO_AWARD_FLIGHTS
                );
                return { airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] };
              })
            );
        } else {
          this.setNotificationWarning(NotificationWarning.NO_FLIGHTS);
          return of({ airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] });
        }
      }),
      retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
      catchError(() => {
        this.setNotificationWarning(NotificationWarning.GENERAL);

        return of({ airCalendars: undefined, status: StatusCode.NO_FLIGHTS_FOUND, messages: [] });
      }),
      share()
    );
  }

  checkFlightAvailabilityAndContinue(params: FlightSearchParams): Observable<boolean> {
    return iif(
      () => params.isAward,
      this.checkOffersAvailability(params),
      this.checkAirboundsAvailability(params)
    ).pipe(
      withLatestFrom(this.store$.pipe(notificationWarnings(), finShare())),
      map(([response, storeNotificationMap]: [OfferList | FinnairAirBoundsResponse, Record<string, WarningData>]) => {
        if (!response || !response?.status) {
          this.setNotificationWarning(NotificationWarning.GENERAL);
          return false;
        }

        if (storeNotificationMap[NotificationWarning.GENERAL]?.isActive) {
          // remove the general notification if stored previously
          this.setNotificationWarning(NotificationWarning.GENERAL, false);
        }

        const hasAvailability = ![StatusCode.NO_FLIGHTS_FOUND, StatusCode.NO_FLIGHTS_LEFT_FOR_TODAY].includes(
          response.status
        );

        if (!hasAvailability) {
          this.setNotificationWarning(NotificationWarning.NO_FLIGHTS);
        }
        return hasAvailability;
      })
    );
  }

  checkOffersAvailability(params: FlightSearchParams): Observable<OfferList | undefined> {
    const offersRequest: FinnairSearchParameters = this.createOffersRequest(params);

    return this.offersService
      .findOfferList1(this.configService.cfg.offersUrl, { body: offersRequest })
      .pipe(share())
      .pipe(
        retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
        catchError(() => of(undefined)),
        finShare()
      );
  }

  checkAirboundsAvailability(params: FlightSearchParams): Observable<FinnairAirBoundsResponse | undefined> {
    const airBoundsRequest: FinnairAirBoundsRequest = this.createAirboundsRequest(params);
    return this.offersService
      .findAirBounds(this.configService.cfg.offersUrl, { body: airBoundsRequest })
      .pipe(share())
      .pipe(
        retryWithBackoff(BookingWidgetCalendarService.NUMBER_OF_RETRIES),
        catchError(() => of(undefined)),
        finShare()
      );
  }

  private setNotificationWarning(type: NotificationWarning, isActive = true, data?: SeasonalNotificationData): void {
    this.store$.dispatch(
      BookingWidgetActions.setNotificationWarning({
        key: type,
        isActive,
        data: data,
      })
    );
  }

  private createOffersItinerary(params: FlightSearchParams): FinnairRequestItineraryItem[] {
    const { flights } = params;

    return flights.map((flight) => ({
      departureDate: flight.departureDate?.id,
      departureLocationCode: flight.origin?.locationCode,
      destinationLocationCode: flight.destination?.locationCode,
    }));
  }

  private createOffersRequest(params: FlightSearchParams): FinnairSearchParameters {
    const itinerary = this.createOffersItinerary(params);
    return {
      adults: params.paxAmount.adults,
      c15s: params.paxAmount.c15s,
      cabin: getBookingWidgetCabinClass(
        params.travelClass,
        params.isCorporate,
        this.configService.cfg.enableCorporateTravelClassLimit
      ) as string as Cabin,
      children: params.paxAmount.children,
      infants: params.paxAmount.infants,
      isAward: params.isAward,
      itinerary,
      locale: params.locale,
      promoCode: params.promoCode,
    };
  }

  private createAirboundsRequest({
    paxAmount,
    flights,
    travelClass,
    isCorporate,
  }: FlightSearchParams): FinnairAirBoundsRequest {
    return {
      cabin: getBookingWidgetCabinClass(
        travelClass,
        isCorporate,
        this.configService.cfg.enableCorporateTravelClassLimit
      ) as string as Cabin,
      locale: this.languageService.localeValue,
      travelers: paxAmount,
      itineraries: flights.map((flight, i) => ({
        departureDate: flight.departureDate.id,
        departureLocationCode: flight.origin.locationCode,
        destinationLocationCode: flight.destination.locationCode,
        directFlights: false,
        isRequestedBound: i === 0,
      })),
    };
  }
}
