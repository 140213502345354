import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  iif,
  map,
  of,
  startWith,
  switchMap,
  take,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { ConsentTextId } from '@fcom/common';
import { ConsentService } from '@fcom/common/login';
import { LocationPair } from '@fcom/common/store';
import { AppState, GeolocationService, LatLng, LocationRouteCffService } from '@fcom/core';
import { Location, LocationGeoLocData, LocationMatchData } from '@fcom/core-api';
import { akamaiGeolocation, isBrowserGeolocationLoading } from '@fcom/core/selectors';
import { finShare, retryWithBackoff } from '@fcom/rx';
import {
  ButtonMode,
  ButtonSize,
  ButtonTheme,
  IconButtonSize,
  IconButtonTheme,
  IconPosition,
  LoaderTheme,
  TextInputComponent,
  PopoverOptions,
  PopoverService,
} from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { isNotBlank, isPresent, stopPropagation, unsubscribe, isBlank } from '@fcom/core/utils';
import { TransportType } from '@fcom/common-booking/interfaces/utils.interface';

import { GtmLocationEventType, Locations, LocationType, LocationParam } from '../../interfaces';
import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { BookingWidgetService } from '../../services/booking-widget.service';
import { BOOKING_WIDGET_CONTEXT, defaultWidgetPopoverOptions } from '../../constants';
import { BookingWidgetFlightService } from '../../services/booking-widget-flight.service';

@Component({
  selector: 'fin-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectorComponent implements AfterViewInit, OnInit, OnDestroy {
  readonly LoaderTheme = LoaderTheme;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly IconPosition = IconPosition;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TransportType = TransportType;

  readonly originPopoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'locationSelectorPopover',
    alignToLeft: true,
    popoverWidth: 456,
    disableAutoFocus: true,
  };
  readonly destinationPopoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'locationSelectorPopover',
    popoverWidth: 456,
    disableAutoFocus: true,
  };

  @Input()
  disabled = false;

  @Input()
  locationPair$: Observable<LocationPair>;

  @Input()
  showSeparator = true;

  @Input()
  showLocateMe = true;

  @Input()
  allowedOriginContinent: string;

  @Input()
  allowedDestinationContinent: string;

  @Input()
  originDisabled: boolean;

  @Input()
  destinationDisabled: boolean;

  @Input()
  showPreviousSearches?: boolean;

  @Input()
  isAm = false;

  @Input()
  showSuggestedDestinations = true;

  @Input()
  amOrigins$: Observable<Location[]> = of(undefined);

  @Input()
  amDestinations$: Observable<Location[]> = of(undefined);

  @Input()
  isGlobalBookingWidget = false;

  @Input()
  highLightOrigin$ = of(false);
  @Input()
  highLightDestination$ = of(false);

  @Input()
  enableNewSearchAutomatically = false;

  @Output()
  setLocations = new EventEmitter<LocationParam>();

  @Output()
  startSearch = new EventEmitter<void>();

  modalOpen = false;
  locationType$: BehaviorSubject<'origin' | 'destination'> = new BehaviorSubject('origin');
  GtmLocationEventType$: BehaviorSubject<'locations' | 'suggestions'> = new BehaviorSubject('locations');
  defaultOrigin$: Observable<Location>;
  origin$: Observable<Location>;
  destination$: Observable<Location>;
  enablePersonalization$: Observable<boolean>;
  defaultLocations$: Observable<Location[]>;
  locationResults$: BehaviorSubject<Location[]> = new BehaviorSubject(undefined);
  locationSearchForm: FormGroup<{ locationSearch: FormControl<string> }>;
  queryString$: Observable<string>;
  hasSearch$: Observable<boolean>;
  isGeoLocating$: Observable<boolean>;
  activeIndex$ = new BehaviorSubject<number>(undefined);
  hasLocations$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  locationsSwitched = new EventEmitter<void>();
  locationSelected = new EventEmitter<{ location: Location; locationType: LocationType }>();

  private subscriptions: Subscription = new Subscription();

  @ViewChild('locationListHeading') locationListHeading: ElementRef;
  @ViewChild('searchInput') searchInput: TextInputComponent;
  @ViewChildren('locationItem', { read: ElementRef }) locationItems: QueryList<ElementRef>;
  readonly usePopoverSelectors = this.bookingWidgetService.usePopoverSelectors();

  constructor(
    private store$: Store<AppState>,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private geoLocationService: GeolocationService,
    private consentService: ConsentService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private bookingWidgetService: BookingWidgetService,
    private popoverService: PopoverService,
    private bookingWidgetFlightService: BookingWidgetFlightService
  ) {}

  ngOnInit(): void {
    this.isGeoLocating$ = this.store$.pipe(isBrowserGeolocationLoading());

    this.defaultOrigin$ = this.store$.pipe(akamaiGeolocation()).pipe(
      switchMap((value: LatLng) =>
        this.locationRouteCffService.geolocMatchFor(value.lat, value.lng, this.languageService.localeValue)
      ),
      switchMap((location: LocationGeoLocData) =>
        this.locationRouteCffService.bestGuessFor(location.item.locationCode, this.languageService.localeValue)
      ),
      retryWithBackoff(2),
      catchError((err: unknown) => {
        return throwError(() => err);
      }),
      distinctUntilChanged(),
      finShare()
    );

    this.locationSearchForm = new FormGroup({
      locationSearch: new FormControl(''),
    });

    this.defaultLocations$ = iif(
      () => this.isAm,
      combineLatest([this.amOrigins$, this.amDestinations$, this.locationType$]).pipe(
        map(([amOrigins, amDestinations, locationType]) => (locationType === 'origin' ? amOrigins : amDestinations))
      ),
      combineLatest([
        this.bookingWidgetFlightService.suggestedLocations$,
        this.bookingWidgetFlightService.trendingDestinations$,
        this.locationType$,
      ]).pipe(
        map(([suggestedLocations, trendingLocations, locationType]) =>
          this.showSuggestedDestinations
            ? locationType === 'origin'
              ? suggestedLocations?.departures
              : trendingLocations
                ? trendingLocations
                : suggestedLocations?.destinations
            : undefined
        )
      )
    ).pipe(startWith(undefined), finShare());

    this.enablePersonalization$ = this.consentService
      .getCookieConsentStatusById(ConsentTextId.COOKIE_PERSONALIZATION)
      .pipe(distinctUntilChanged());

    this.queryString$ = this.locationSearchForm
      .get('locationSearch')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith(''), finShare());

    this.hasLocations$ = combineLatest([this.locationResults$, this.defaultLocations$]).pipe(
      map(([results, defaults]) => isPresent(results) || isPresent(defaults))
    );

    this.subscriptions.add(
      this.queryString$.pipe(filter(isNotBlank)).subscribe((queryString) => {
        this.GtmLocationEventType$.next(`${isBlank(queryString) ? 'locations' : 'suggestions'}`);
        this.sendGtmEvent(GtmLocationEventType.INPUT, queryString);
      })
    );

    this.subscriptions.add(
      this.queryString$
        .pipe(
          filter(isNotBlank),
          withLatestFrom(this.locationType$),
          switchMap(([queryString, locationType]) => {
            if (this.isAm) {
              return this.searchAmHolidays(queryString, locationType);
            }

            const continent =
              locationType === 'origin' ? this.allowedOriginContinent : this.allowedDestinationContinent;
            return this.searchFlights(queryString, continent);
          })
        )
        .subscribe((locations: Location[]) => {
          this.locationResults$.next(locations);
        })
    );

    this.subscriptions.add(
      this.queryString$.subscribe(() => {
        if (!this.isAm) {
          this.locationResults$.next(undefined);
        }

        this.activeIndex$.next(undefined);
      })
    );

    this.isLoading$ = combineLatest([this.queryString$, this.locationResults$]).pipe(
      map(([queryString, results]) => isNotBlank(queryString) && !isPresent(results))
    );

    this.subscriptions.add(
      this.locationsSwitched.pipe(withLatestFrom(this.locationPair$)).subscribe(([, { origin, destination }]) => {
        this.setLocations.emit({
          locations: { origin: destination, destination: origin },
          locationType: [LocationType.DESTINATION, LocationType.ORIGIN],
        });

        if (this.enableNewSearchAutomatically) {
          this.bookingWidgetService.resetSearchHighlight();
          this.startSearch.emit();
        }
      })
    );

    this.subscriptions.add(
      this.locationSelected
        .pipe(withLatestFrom(this.locationPair$))
        .subscribe(([{ location, locationType }, flight]) => {
          const oppositeLocationType = locationType === 'origin' ? 'destination' : 'origin';

          const newLocations =
            location.locationCode === flight[oppositeLocationType]?.locationCode
              ? {
                  [locationType]: location,
                  [oppositeLocationType]: undefined,
                }
              : {
                  ...flight,
                  [locationType]: location,
                };

          this.sendGtmEvent(GtmLocationEventType.SELECTION, location.locationCode);
          this.setLocations.emit({ locations: newLocations, locationType: [locationType] });
          this.handleClose(false);
        })
    );

    //set default location
    if (!this.isGlobalBookingWidget) {
      this.bookingWidgetFlightService.fetchUserLocationAndSetDefaultOrigin();
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.activeIndex$
        .pipe(filter((i) => isPresent(i) && isPresent(this.locationItems.get(i)?.nativeElement)))
        .subscribe((i) => {
          this.locationItems.get(i)?.nativeElement.focus();
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  handleInitialLocationItemFocus(index: number): void {
    if (!isPresent(this.activeIndex$.getValue())) {
      this.activeIndex$.next(index);
    }
  }

  handleOpen(type: keyof Locations): void {
    if (!this.bookingWidgetService.usePopoverSelectors()) {
      this.modalOpen = true;
    }

    this.locationType$.next(type);
    this.locationSearchForm.reset({ locationSearch: '' }, { emitEvent: false });
  }

  handleClose(logEvent = true): void {
    this.modalOpen = false;
    this.popoverService.closeByContext(BOOKING_WIDGET_CONTEXT);
    this.resetSearch();
    if (logEvent) {
      this.sendGtmEvent(GtmLocationEventType.CLOSE, this.locationSearchForm.get('locationSearch').value);
    }
  }

  closePopover(): void {
    this.popoverService.close(true);
  }

  resetSearch(): void {
    this.locationSearchForm.controls.locationSearch.setValue('');
    this.locationResults$.next(undefined);
    this.activeIndex$.next(undefined);
  }

  setLocationOrStartNewSearch(location: Location, locationType: LocationType): void {
    this.locationSelected.emit({ location, locationType });

    if (this.enableNewSearchAutomatically) {
      this.startSearch.emit();
    }
  }

  moveFocusToResults(e: Event): void {
    stopPropagation(e);
    this.locationListHeading?.nativeElement?.focus();
  }

  moveFocusToSearchInput(): void {
    this.searchInput?.inputElement?.nativeElement?.focus();
  }

  getUserLocation(): void {
    this.subscriptions.add(
      this.geoLocationService
        .getGeolocationFromNavigator(NEVER)
        .pipe(take(1))
        .subscribe((location: Location) => {
          this.handleClose();
          this.setLocations.emit({ locations: { origin: location }, locationType: [LocationType.ORIGIN] });
        })
    );
  }

  handleKeyDown(e: KeyboardEvent, locations: Location[]): void {
    const currentActiveIndex = this.activeIndex$.getValue();
    const maxIndex = locations ? locations.length - 1 : 0;

    switch (e.key) {
      case 'Down':
      case 'ArrowDown':
        stopPropagation(e);
        this.activeIndex$.next(currentActiveIndex + 1 > maxIndex ? 0 : currentActiveIndex + 1);
        break;
      case 'Up':
      case 'ArrowUp':
        stopPropagation(e);
        this.activeIndex$.next(currentActiveIndex - 1 < 0 ? maxIndex : currentActiveIndex - 1);
        break;
      default:
        break;
    }
  }

  private searchFlights(queryString: string, continent: string): Observable<Location[]> {
    return isBlank(queryString)
      ? of(undefined)
      : this.locationRouteCffService
          .locationMatchesFor(queryString, this.languageService.localeValue, undefined, false, continent)
          .pipe(map((data: LocationMatchData) => data.items));
  }

  private searchAmHolidays(queryString: string, locationType: string): Observable<Location[]> {
    return isBlank(queryString)
      ? of(undefined)
      : iif(() => locationType === 'origin', this.amOrigins$, this.amDestinations$).pipe(
          map((amLocations) => this.searchAmLocations(queryString, amLocations))
        );
  }

  private searchAmLocations(query: string, locations: Location[]): Location[] {
    const lcQuery = query.toLowerCase();
    if (locations.some((location) => location.title.toLowerCase().includes(lcQuery))) {
      return locations.sort((a, b) => {
        const lcA = a.title.toLowerCase();
        const lcB = b.title.toLowerCase();

        if (!lcA.includes(lcQuery) && !lcB.includes(lcQuery)) {
          return 0;
        } else if (lcA.includes(lcQuery) && !lcB.includes(lcQuery)) {
          return -1;
        } else if (lcB.includes(lcQuery) && !lcA.includes(lcQuery)) {
          return 1;
        }

        return lcA.indexOf(lcQuery) - lcB.indexOf(lcQuery);
      });
    }

    // levenshtein algorithm from old widget doesn't work properly with exact matches like "lon" or "oul"
    // but it works fine for cases when there is no exact match like "oulo"
    return locations.sort(locationLevenshteinPartialMatchSort(lcQuery));
  }

  private sendGtmEvent(eventType: GtmLocationEventType, state: string): void {
    this.bookingWidgetGtmService.trackElementEvent(
      `${this.locationType$.getValue()}-${this.GtmLocationEventType$.getValue()}-modal-${eventType}`,
      state
    );
  }
}

/**
 * (not so) Amazing sorting algorithm (copied from old widget) to always find the location you wanted!
 * Uses Levenshtein distance but prioritizes case-insensitive partial matches.
 */
export const locationLevenshteinPartialMatchSort = (query: string) => (a: Location, b: Location) =>
  levenshteinDistance(query, a.title) +
  levenshteinDistance(query, a.country) -
  (levenshteinDistance(query, b.title) + levenshteinDistance(query, b.country)) -
  (partialMatch(a.title, query) ? 100 : 0) -
  (partialMatch(a.country, query) ? 100 : 0) +
  (partialMatch(b.country, query) ? 100 : 0) +
  (partialMatch(b.title, query) ? 100 : 0);

const partialMatch = (a: string, b: string): boolean => a.toLowerCase().includes(b.toLowerCase());

const levenshteinDistance = (a = '', b = ''): number => {
  const distanceMatrix = Array(b.length + 1)
    .fill(null)
    .map(() => Array(a.length + 1).fill(null));

  for (let i = 0; i <= a.length; i += 1) {
    distanceMatrix[0][i] = i;
  }

  for (let j = 0; j <= b.length; j += 1) {
    distanceMatrix[j][0] = j;
  }

  for (let j = 1; j <= b.length; j += 1) {
    for (let i = 1; i <= a.length; i += 1) {
      const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
      distanceMatrix[j][i] = Math.min(
        distanceMatrix[j][i - 1] + 1, // deletion
        distanceMatrix[j - 1][i] + 1, // insertion
        distanceMatrix[j - 1][i - 1] + indicator // substitution
      );
    }
  }

  return distanceMatrix[b.length][a.length];
};
