import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  Subscription,
  take,
  withLatestFrom,
  map,
  distinctUntilChanged,
} from 'rxjs';

import { TripType } from '@fcom/core/interfaces';
import { stopPropagation } from '@fcom/core/utils';
import { CmsTripTypeMap } from '@fcom/core/utils/tripType.utils';
import { ButtonTheme, RadioButtonTheme, PopoverOptions, PopoverService } from '@fcom/ui-components';
import { ConfigService, SentryLogger } from '@fcom/core';

import { BookingWidgetService } from '../../services/booking-widget.service';
import { BookingWidgetTripService } from '../../services/booking-widget-trip.service';
import { defaultWidgetPopoverOptions } from '../../constants';

@Component({
  selector: 'fin-trip-type-selector',
  styleUrls: ['./trip-type-selector.component.scss'],
  templateUrl: './trip-type-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripTypeSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  disabled = false;

  @Input()
  returnDateMissing = false;

  @Input()
  isSearchEnabled = false;

  @Input()
  highLight$: Observable<boolean>;

  @Input()
  isGlobalBookingWidget = false;

  @Input()
  enableMultiCity = false;

  @Input()
  enableNewSearchAutomatically: boolean;

  @Output()
  setTripType = new EventEmitter<TripType>();

  @Output()
  startSearch = new EventEmitter<void>();

  @Output()
  openCalendar = new EventEmitter<void>();

  readonly selectedTripType$: Observable<TripType>;
  readonly availableTripTypes$: Observable<TripType[]>;
  readonly FORM_CONTROL_NAME = 'tripType';
  readonly RadioButtonTheme = RadioButtonTheme;
  readonly ButtonTheme = ButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;
  readonly CmsTripTypeMap = CmsTripTypeMap;
  readonly popoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'tripTypeSelectorPopover',
    alignToLeft: true,
    disableAutoFocus: true,
  };
  addingReturnDate = false;

  onAddReturnDate(): void {
    this.addingReturnDate = true;
    this.openCalendar.emit();
    this.closePopover();
  }

  modalOpen = false;
  selectedElement: ElementRef = null;
  currentTripType$ = new BehaviorSubject<TripType>(TripType.RETURN);
  selectedTripTypeIndex$ = new BehaviorSubject<number>(0);
  subscription = new Subscription();
  showNewMultiCityTag: boolean;
  popOverFormGroup: FormGroup = new FormGroup({
    [this.FORM_CONTROL_NAME]: new FormControl(),
  });
  tripTypeOptions$: Observable<{ value: TripType; label: string }[]>;
  isBrowser = false;

  @ViewChildren('tripTypeRadioBtn', { read: ElementRef }) tripTypeRadioBtn: QueryList<ElementRef>;

  constructor(
    private popoverService: PopoverService,
    private bookingWidgetService: BookingWidgetService,
    private bookingWidgetTripService: BookingWidgetTripService,
    private configService: ConfigService,
    private sentryLogger: SentryLogger,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.selectedTripType$ = this.bookingWidgetTripService.selectedTripType$;
    this.availableTripTypes$ = this.bookingWidgetTripService.availableTripTypes$.pipe(
      map((tripTypes) => (this.isGlobalBookingWidget ? tripTypes.filter((t) => t !== TripType.MULTICITY) : tripTypes))
    );
  }

  ngOnInit(): void {
    /**
     * This check is used to only render the multi-city help content popover on the client. The
     * reason is currently unknown but we get an angular hydration error if you try to remove it.
     */
    this.isBrowser = isPlatformBrowser(this.platform);

    this.tripTypeOptions$ = this.availableTripTypes$.pipe(
      map((tripTypes) =>
        tripTypes
          .filter((tripType) => {
            if (!CmsTripTypeMap[tripType]) {
              this.sentryLogger.warn('TripType is not defined in CmsTripTypeMap', { tripType });
            }
            return !!CmsTripTypeMap[tripType];
          })
          .map((tripType) => ({
            value: tripType,
            label: `travelType.${CmsTripTypeMap[tripType]}`,
          }))
      )
    );

    this.subscription.add(
      this.selectedTripType$
        .pipe(withLatestFrom(this.availableTripTypes$))
        .subscribe(([tripType, availableTripTypes]) => {
          const selectedIndex = availableTripTypes.findIndex((t) => t === tripType);
          this.selectedTripTypeIndex$.next(selectedIndex);
          this.currentTripType$.next(availableTripTypes[selectedIndex]);
        })
    );

    if (this.bookingWidgetService.usePopoverSelectors()) {
      this.subscription.add(
        this.selectedTripType$.subscribe((tripType) => {
          this.popOverFormGroup.controls[this.FORM_CONTROL_NAME].setValue(tripType);
        })
      );
      this.subscription.add(
        this.popOverFormGroup
          .get(this.FORM_CONTROL_NAME)
          .valueChanges.pipe(distinctUntilChanged())
          .subscribe((value) => {
            this.setTripType.emit(value);
          })
      );
    }

    this.showNewMultiCityTag = this.configService.cfg.enableMultiCity;
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.selectedTripTypeIndex$
        .pipe(withLatestFrom(this.availableTripTypes$))
        .subscribe(([index, availableTripTypes]) => {
          this.currentTripType$.next(availableTripTypes[index]);
          this.tripTypeRadioBtn.get(index)?.nativeElement?.focus();
        })
    );
  }

  handleKeyPress(selectedTripType: TripType, event: KeyboardEvent): void {
    stopPropagation(event);
    const currentIndex = this.selectedTripTypeIndex$.getValue();
    const maxIndex = this.tripTypeRadioBtn.length - 1;

    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.selectedTripTypeIndex$.next(currentIndex + 1 > maxIndex ? 0 : currentIndex + 1);
        break;
      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.selectedTripTypeIndex$.next(currentIndex - 1 < 0 ? maxIndex : currentIndex - 1);
        break;
      case 'Enter':
      case ' ':
        this.selectTripType(selectedTripType);
        break;
      default:
        break;
    }
  }

  openModal(): void {
    if (this.bookingWidgetService.usePopoverSelectors()) {
      return;
    }

    this.modalOpen = true;
  }

  cancelSelection(): void {
    this.subscription.add(
      this.bookingWidgetService.currentSearch$.pipe(take(1)).subscribe((originalSelection) => {
        this.bookingWidgetService.setTripType(originalSelection.tripType);
        this.bookingWidgetService.setTravelDates(
          {
            departureDate: originalSelection.flights[0]?.departureDate,
            returnDate: originalSelection.flights[1]?.departureDate,
          },
          0
        );
        this.bookingWidgetService.resetSearchHighlight();
        this.popoverService.close(true);
      })
    );
  }

  selectTripType(selectedTripType: TripType): void {
    this.modalOpen = false;
    this.setTripType.emit(selectedTripType);
  }

  closePopover(): void {
    this.popoverService.close(true);
  }

  onClose(): void {
    if (this.enableNewSearchAutomatically) {
      if (!this.addingReturnDate) {
        this.cancelSelection();
      }
      this.addingReturnDate = false;
    }
  }

  //TODO: check if this function is needed
  confirmOnClose(): void {
    this.subscription.add(
      combineLatest([this.selectedTripType$, this.currentTripType$])
        .pipe(
          filter(([selected, current]) => selected !== current),
          take(1)
        )
        .subscribe(([_selected, current]) => {
          this.setTripType.emit(current);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription = null;
  }
}
