import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { equals } from '@fcom/core/utils/equals';
import { getCurrentBreakPoint } from '@fcom/common/utils/layout.utils';
import { Breakpoint } from '@fcom/common/interfaces/breakpoint.interface';
import { FinnairOperatingAirlineId } from '@fcom/dapi/api/models';

import { ResponsiveImage } from '../../images/interfaces';
import { AspectRatios } from '../../images';
import { JourneyType } from '../interface';
import { IconPosition } from '../../icons';
import { ButtonTheme } from '../../buttons';

@Component({
  selector: 'fcom-trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss'],
})
export class TripCardComponent implements OnChanges, OnDestroy {
  readonly JourneyType = JourneyType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;
  /**
   * A string representing the title of the trip card
   */
  @Input()
  title: string;

  /**
   * An object containing the image of the next destination and the alt text to use for
   * accessibility.
   *
   * If an image isn't provided then a background of grey-200 will be used
   */
  @Input()
  image: { alt: string } & ResponsiveImage;

  /**
   * An array of cities to display in the trip card. You should pass every city at the start and
   * end of a bound. For example Stockholm to Hong Kong should show only these cities are not
   * include Helsinki.
   *
   * Note -There is special logic using this input
   * 1. The journey type (one way, return, complex) is calculated from this input.
   * 2. For return journeys the final destination is not shown.
   */
  @Input()
  destinations: string[] = [];

  /**
   * The departure date of the trip.
   * @type {string}
   */
  @Input()
  departureDate: string;

  /**
   * The arrival date of the trip.
   * @type {string}
   */
  @Input()
  arrivalDate: string;

  /**
   * The number of passengers (including infants and children).
   */
  @Input()
  passengerAmount: number;

  /**
   * The booking reference / PNR / record locator for the booking.
   */
  @Input()
  bookingRef: string;

  /**
   * The total price amount for the flight.
   * @type {string}
   */
  @Input()
  flightTotalPriceAmount: string;

  /**
   * Total points for the flight
   * @type {string}
   */
  @Input()
  totalPoints: string;

  /**
   * A flag indicating whether the expanded area is opened or closed.
   * @type {boolean}
   */
  @Input()
  expanded = false;

  /**
   * A flag indicating whether the start over button should be shown.
   * @type {boolean}
   */
  @Input()
  showStartOverButton = false;

  /**
   * A flag indicating whether the expand button should be shown.
   * @type {boolean}
   */
  @Input()
  showExpandButton = true;

  @Input()
  operatingAirlineIds: FinnairOperatingAirlineId[] = [];

  /**
   * Emits when the CTA element has been clicked
   * @type {EventEmitter}
   */
  @Output()
  ctaClick: EventEmitter<void> = new EventEmitter();

  /**
   * Emits a boolean value indicating whether the component is expanded or not
   * @type {EventEmitter<boolean>}
   */
  @Output()
  expandedChange = new EventEmitter<boolean>();

  journeyType: JourneyType;
  journeyIcon: SvgLibraryIcon;
  destinationPairs: string[][];
  expandContainerId = uuid();
  expandButtonId = uuid();
  ngDestroyed$ = new Subject();
  imageRatio$: BehaviorSubject<keyof typeof AspectRatios> = new BehaviorSubject<keyof typeof AspectRatios>('16x9');

  @ViewChild('notifications') notifications: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.inferAdditionalBookingInfo();
    this.cd.markForCheck();
    this.updateAspectRatio();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateAspectRatio();
  }

  private updateAspectRatio() {
    const checkAspectForMobile = getCurrentBreakPoint() === Breakpoint.MOBILE && this.notifications;
    if (
      checkAspectForMobile &&
      this.notifications.nativeElement.clientHeight / 9 > this.notifications.nativeElement.clientWidth / 16
    ) {
      this.imageRatio$.next('1x1');
    } else {
      this.imageRatio$.next('16x9');
    }
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
  }

  /**
   * Infer additional information from the inputs provided to the component
   */
  inferAdditionalBookingInfo(): void {
    const pairs: string[][] = [];
    this.destinations.forEach((destination: string, index: number) => {
      if (index % 2 === 1 || !this.destinations[index + 1]) {
        return;
      }
      pairs.push([destination, this.destinations[index + 1]]);
    });
    this.destinationPairs = pairs.reduce((acc: string[][], pair) => {
      // Filter out matching return flight pairs, e.g.
      // [['Helsinki', 'London'], ['London', 'Helsinki]] => [['Helsinki', 'London']]
      if (acc.some((search) => equals(search, [pair[1], pair[0]]))) {
        return acc;
      }
      acc.push(pair);
      return acc;
    }, []);

    if (this.destinationPairs.length > 1) {
      this.journeyType = JourneyType.COMPLEX;
    } else if (
      this.destinations.length > 2 &&
      this.destinations[0] === this.destinations[this.destinations.length - 1]
    ) {
      this.journeyType = JourneyType.RETURN;
    } else {
      this.journeyType = JourneyType.ONEWAY;
    }
    switch (this.journeyType) {
      case JourneyType.ONEWAY:
        this.journeyIcon = SvgLibraryIcon.AIRCRAFT_HORIZONTAL;
        break;
      case JourneyType.RETURN:
        this.journeyIcon = SvgLibraryIcon.RETURN_TRIP;
        break;
      case JourneyType.COMPLEX:
      default:
        this.journeyIcon = SvgLibraryIcon.AIRCRAFT_TILTED;
    }
  }

  toggleExpand(event: MouseEvent): void {
    // The show details link is inside another event handler and triggers twice. Stop this event
    // from propagating.
    event.stopPropagation();

    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  clickCta(event: Event): void {
    this.ctaClick.emit();
    event.preventDefault();
  }

  trackByFn(index: number): number {
    return index;
  }
}
