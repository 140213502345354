<div
  class="tag medium-type font-body-2 flex--inline flex--middle align-center ps-xsmall-x rounded-medium"
  [ngClass]="{
    'white-text': theme === TagTheme.ACTIVE || theme === TagTheme.POPULAR,
    'nordic-blue-900-text nordic-blue-25-bg': theme === TagTheme.DEFAULT,
    'nordic-blue-900-bg': theme === TagTheme.ACTIVE,
    'white-bg nordic-blue-900-text': theme === TagTheme.WHITE,
    'birch-50-bg birch-600-text': theme === TagTheme.SUSTAINABILITY,
    'heather-700-bg': theme === TagTheme.POPULAR,
    'nordic-blue-900-text success-50-bg': theme === TagTheme.SUCCESS,
    'nordic-blue-900-text alert-50-bg': theme === TagTheme.WARNING,
    'nordic-blue-900-text error-50-bg': theme === TagTheme.ALERT,
    'border-rounded-chip': roundingStyle === TagRoundingStyle.CHIP,
    'border-rounded-bottom-left': roundingStyle === TagRoundingStyle.ONLY_BOTTOM_LEFT,
  }"
>
  <ng-content></ng-content>
</div>
