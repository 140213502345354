import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, Observable, of, Subject, switchMap, Subscription, NEVER } from 'rxjs';
import { buffer, catchError, debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { CmsCollection, CmsOffer } from '@fcom/core-api';
import { ConfigService, GlobalBookingTravelClass, LocationRouteCffService, stopPropagation } from '@fcom/core';
import {
  ElementActions,
  ElementTypes,
  GaContext,
  MultivariateTestId,
  PageMetaService,
  PriceCalendarCTAParams,
  PriceCalendarParams,
  PriceCalendarService,
  PromotionItem,
  PromotionType,
  RecommendationService,
  TestVariant,
  TripType,
} from '@fcom/common';
import {
  TrendingDestinationLocation,
  TrendingDestinationsBaseLocation,
} from '@fcom/common/interfaces/recommendation.interface';
import { GtmService } from '@fcom/common/gtm';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';
import { unsubscribe } from '@fcom/core/utils';
import { AmDestinationService } from '@fcom/destination-search/services/am-destination.service';
import { getOfferWithAmDestination } from '@fcom/common/utils';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';
import { LocationsService } from '@fcom/locations/services/locations.service';
import { DestinationSearchService } from '@fcom/destination-search/services/destination-search.service';
import { DestinationSearchItem } from '@fcom/destination-search/interfaces/destination-search.interface';

import { BlockOffer, BlockOfferData, MarketingOffer, MarketingOfferPrices } from '../../interfaces';
import { CheapestPriceForAllDestinationsService, toPromoBlock } from '../../services';

const TRENDING_BADGE_LABEL = 'popularNow';

@Component({
  selector: 'fin-trending-destinations-grid',
  template: `
    <fin-offer-grid
      *ngIf="originLocationCode$ | async as originLocationCode"
      [title]="(isRelevantOffersTestVariant$ | async) ? ('europeUnder100' | finLocalization) : content.teaserTitle"
      [offers$]="offers$"
      [cmsTags]="content.subjectTaxonomyTags || []"
      [tripType]="tripType"
      (destinationClicked)="destinationClick($event)"
      (scrolledPastDestination)="scrollEvents$.next($event)"
      (flightHotelClicked)="openAmContinueModal($event)"
      (calendarClicked)="calendarClicked(originLocationCode, $event)"
      [fetchImagePriority]="'low'"
      [mobileCarousel]="isRelevantOffersTestVariant$ | async"
      [noHeaderPadding]="isRelevantOffersTestVariant$ | async"
    ></fin-offer-grid>
    <fin-am-destination-continue-modal
      [open]="isAmContinueModalOpen"
      (closeModal)="closeAmContinueModal()"
      [bookUrl]="flightHotelUrl"
    ></fin-am-destination-continue-modal>
    <fcom-modal
      [open]="(calendarOpen$ | async) !== null"
      [fitToContent]="true"
      [buttons]="null"
      [roundedContent]="true"
      [fullHeight]="true"
      [extraWide]="true"
      (close)="closePriceCalendar()"
      data-testid="price-calendar-modal"
    >
      <fin-price-calendar
        [openWithParams$]="calendarOpen$"
        [showAddReturn]="true"
        [showSubtitle]="true"
        [ctaLabel]="'bookingSearch.search' | finLocalization"
        [resetCalender]="true"
        (modalClose)="closePriceCalendar()"
        (ctaClicked)="priceCalendarCTAClicked($event)"
      ></fin-price-calendar>
    </fcom-modal>
  `,
})
export class TrendingDestinationsComponent implements OnInit, OnDestroy {
  @Input()
  content: CmsCollection;

  offers$: Observable<BlockOfferData[]>;
  scrollEvents$: Subject<[BlockOffer, number]> = new Subject<[BlockOffer, number]>();
  flightHotelUrl: string;
  tripType: TripType;
  originLocationCode$: Observable<string>;
  calendarOpen$: Observable<PriceCalendarParams | null> = NEVER;
  isRelevantOffersTestVariant$: Observable<boolean>;

  isAmContinueModalOpen = false;

  readonly scrollDebounceTime = 1000;
  readonly popularTrendLimit = 2;
  readonly maxNumberOfOffers = 8;
  readonly minNumberOfOffers = 4;

  private subscriptions = new Subscription();

  constructor(
    private recommendationService: RecommendationService,
    private pageMetaService: PageMetaService,
    private gtmService: GtmService,
    private languageService: LanguageService,
    private cheapestPriceForAllDestinationsService: CheapestPriceForAllDestinationsService,
    private amDestinationService: AmDestinationService,
    private locationRouteCffService: LocationRouteCffService,
    private priceCalendarService: PriceCalendarService,
    private multivariateTestService: MultivariateTestService,
    private locationsService: LocationsService,
    private configService: ConfigService,
    private destinationSearchService: DestinationSearchService
  ) {
    this.calendarOpen$ = this.priceCalendarService.calendarOpen$;
    this.originLocationCode$ = this.pageMetaService.defaultOriginLocationCode$;
  }

  ngOnInit(): void {
    this.tripType = this.getTripType();

    this.isRelevantOffersTestVariant$ = this.configService.cfg.enableEuropeUnder100AbTest
      ? this.multivariateTestService.getTestVariant(MultivariateTestId.RELEVANT_OFFERS).pipe(
          take(1),
          map(({ variant }) => variant === TestVariant.B),
          finShare()
        )
      : of(false);

    this.offers$ = combineLatest([
      this.isRelevantOffersTestVariant$,
      this.pageMetaService.defaultOriginLocationCode$,
      this.cheapestPriceForAllDestinationsService.bothWayOffers(),
    ]).pipe(
      switchMap(([isRelevantOffersTestVariant, origin, offers]) =>
        (isRelevantOffersTestVariant
          ? this.getEuropeUnder100(origin)
          : this.recommendationService.getTrendingDestinations(origin).pipe(catchError(() => of([])))
        ).pipe(
          // filter out current pageMeta location if found and convert first n items to BlockOfferData[]
          map((locations) => {
            const filteredDestination = locations
              .filter((item: TrendingDestinationLocation) => item.locationCode !== origin)
              .map((location: TrendingDestinationLocation) =>
                this.trendingToOffer(location, ...this.getBadgeLabelAndIcon(location))
              )
              .filter((item: BlockOfferData) => this.flightPriceFilter(item, offers, this.tripType));
            return filteredDestination.slice(0, this.getAmountOfOffers(filteredDestination));
          }),
          // fallback to CMS content if the recommendation api fails to deliver
          switchMap((locations: BlockOfferData[]) =>
            locations?.length > 0
              ? of(locations)
              : this.languageService.lang.pipe(
                  map((lang) => this.content.items.map((offer) => toPromoBlock(offer as CmsOffer, lang))),
                  map((offs) => offs.slice(0, this.getAmountOfOffers(offs)))
                )
          ),
          switchMap((offs) =>
            this.languageService.localeValue.includes('FI')
              ? getOfferWithAmDestination(
                  offs,
                  this.amDestinationService.amDestinationList(),
                  this.locationRouteCffService
                )
              : of(offs)
          ),
          distinctUntilChanged(),
          finShare()
        )
      )
    );

    this.offers$ = combineLatest([
      this.isRelevantOffersTestVariant$,
      this.pageMetaService.defaultOriginLocationCode$.pipe(filter(Boolean)),
      this.cheapestPriceForAllDestinationsService.bothWayOffers(),
    ]).pipe(
      switchMap(([isRelevantOffersTestVariant, origin, offers]) =>
        (isRelevantOffersTestVariant
          ? this.getEuropeUnder100(origin)
          : this.recommendationService.getTrendingDestinations(origin).pipe(catchError(() => of([])))
        ).pipe(
          // filter out current pageMeta location if found and convert first n items to BlockOfferData[]
          map((locations) => {
            const filteredDestination = locations
              .filter((item: TrendingDestinationLocation) => item.locationCode !== origin)
              .map((location: TrendingDestinationLocation) =>
                this.trendingToOffer(location, ...this.getBadgeLabelAndIcon(location))
              )
              .filter((item: BlockOfferData) => this.flightPriceFilter(item, offers, this.tripType));
            return filteredDestination.slice(0, this.getAmountOfOffers(filteredDestination));
          }),
          // fallback to CMS content if the recommendation api fails to deliver
          switchMap((locations: BlockOfferData[]) =>
            locations?.length > 0
              ? of(locations)
              : this.languageService.lang.pipe(
                  map((lang) => this.content.items.map((offer) => toPromoBlock(offer as CmsOffer, lang))),
                  map((offs) => offs.slice(0, this.getAmountOfOffers(offs)))
                )
          ),
          switchMap((offs) =>
            this.languageService.localeValue.includes('FI')
              ? getOfferWithAmDestination(
                  offs,
                  this.amDestinationService.amDestinationList(),
                  this.locationRouteCffService
                )
              : of(offs)
          ),
          distinctUntilChanged(),
          finShare()
        )
      )
    );

    const debounceScroll$ = this.scrollEvents$.pipe(debounceTime(this.scrollDebounceTime));

    this.subscriptions.add(
      this.scrollEvents$.pipe(buffer(debounceScroll$)).subscribe((events) => {
        const items = events.map(([offer, index]: [BlockOffer, number]) => this.toPromotionItem(offer, index));
        this.gtmService.internalPromotion(items, PromotionType.VIEW);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  destinationClick([destinationItem, index]: [BlockOffer, number]): void {
    this.gtmService.internalPromotion([this.toPromotionItem(destinationItem, index)], PromotionType.CLICK);
  }

  openAmContinueModal({ amLink, destination }: { amLink: string; destination: string }): void {
    this.gtmService.trackElement(
      `tile-${destination.toLowerCase().replace(/ /g, '_')}-flight-hotel`,
      GaContext.CONTENT,
      ElementTypes.LIST_ITEM,
      undefined,
      ElementActions.CLICK
    );
    this.isAmContinueModalOpen = true;
    this.flightHotelUrl = amLink;
  }

  closeAmContinueModal(): void {
    this.isAmContinueModalOpen = false;
  }

  getTripType(): TripType {
    const tripType = TripType[this.content.localSettings?.tripType?.toUpperCase()] ?? TripType.RETURN;
    return this.languageService.localeValue.includes('FI') ? TripType.ONEWAY : tripType;
  }

  calendarClicked(
    selectedOriginLocationCode: string,
    [event, destinationItem, travelClass, tripType]: [Event, BlockOffer, GlobalBookingTravelClass, TripType]
  ): void {
    stopPropagation(event);
    this.priceCalendarService.openPriceCalendar(
      selectedOriginLocationCode,
      destinationItem.destination,
      tripType,
      travelClass,
      destinationItem.title
    );
  }

  priceCalendarCTAClicked(params: PriceCalendarCTAParams): void {
    this.priceCalendarService.priceCalendarCTAClicked(params);
  }

  closePriceCalendar(): void {
    this.priceCalendarService.closePriceCalendar();
  }

  // filter out the destination where oneway price is more expensive than return
  private flightPriceFilter(location: BlockOfferData, offers: MarketingOffer[], tripType: TripType): BlockOfferData {
    const priceOffer = this.getPriceOffer(offers, location);
    //skip checking if the destination doesn't have price available

    if (!priceOffer?.find((i) => i.price.tripType === tripType)?.price?.amount) {
      return null;
    }

    // do not check price if trending destination type is return
    if (tripType === TripType.RETURN) {
      return location;
    }

    if (
      Number(priceOffer?.find((i) => i.price.tripType === TripType.ONEWAY)?.price.amount) <
      Number(priceOffer?.find((i) => i.price.tripType === TripType.RETURN)?.price.amount)
    ) {
      return location;
    }
  }

  private getPriceOffer(offers: MarketingOffer[], location: BlockOfferData): MarketingOfferPrices[] {
    return offers
      .find((offer) => offer.destination === location.destination)
      ?.prices.filter((priceOffer) => priceOffer.travelClass === GlobalBookingTravelClass.ECONOMY);
  }

  private getAmountOfOffers(destination: BlockOfferData[]): number {
    const amountOfOfferWithPrice = destination.length;
    // If amount offer with price < 3, then no offer will be shown
    if (amountOfOfferWithPrice < this.minNumberOfOffers) {
      return 0;
    }
    // If amount offer with price < 8 and >=4, then 4 offers will be returned
    if (amountOfOfferWithPrice < this.maxNumberOfOffers) {
      return this.minNumberOfOffers;
    }
    // others return 9 offers
    return this.maxNumberOfOffers;
  }

  private getBadgeLabelAndIcon(location: TrendingDestinationLocation): [string, SvgLibraryIcon] {
    return !location.isIncludedInCampaign && location.trend >= this.popularTrendLimit
      ? [TRENDING_BADGE_LABEL, SvgLibraryIcon.TRENDING_ARROW_UP]
      : [null, null];
  }

  private trendingToOffer(
    location: TrendingDestinationLocation,
    badgeLabel: string,
    badgeIcon: SvgLibraryIcon
  ): BlockOfferData {
    return {
      title: location.cityName || location.title,
      price: null,
      subtitle: null,
      callToAction: null,
      link: location.destinationUrl,
      flag: location.countryCode,
      imageData: location.picture,
      destination: location.locationCityCode ?? location.locationCode, //destination in marketing offer is locationCityCode if that is available (e.g. REK)
      badgeLabel: location.badgeLabel ?? badgeLabel,
      badgeIcon,
      enableBlackTeaser: location.enableBlackTeaser,
    } as BlockOfferData;
  }

  private toPromotionItem(offer: BlockOfferData, index: number): PromotionItem {
    return {
      position: `${index}`,
      id: 'trending-destinations',
      product: offer.destination,
      type: 'dynamic',
    };
  }

  private getEuropeUnder100(origin: string): Observable<TrendingDestinationLocation[]> {
    return combineLatest([
      this.recommendationService.getTrendingDestinationsByContinent(origin, 'eu').pipe(catchError(() => of([]))),
      this.cheapestPriceForAllDestinationsService.bothWayOffers(),
      this.destinationSearchService.destinationSearch(),
    ]).pipe(
      switchMap(
        ([locations, offers, destinationSearchResult]: [
          TrendingDestinationsBaseLocation[],
          MarketingOffer[],
          DestinationSearchItem[],
        ]) => {
          const finlandDestinations = destinationSearchResult
            .find((continent) => continent.id === 'continenteurope')
            ?.items.find((country) => country.id === 'countryfinland')
            .items.map((destination) => destination.locationCode);

          const locationsUnder100 = locations
            .filter((location) => !finlandDestinations.includes(location.locationCode))
            .map((location) => [
              location,
              parseInt(
                offers
                  .find((offer) => offer.destination === location.locationCode)
                  ?.prices.find((price) => price.travelClass === GlobalBookingTravelClass.ECONOMY)?.price.amount,
                10
              ),
            ])
            .filter(([_location, price]: [TrendingDestinationsBaseLocation, number]) => price < 100)
            .slice(0, this.maxNumberOfOffers)
            .sort(
              (a: [TrendingDestinationsBaseLocation, number], b: [TrendingDestinationsBaseLocation, number]) =>
                a[1] - b[1]
            )
            .map(([location]: [TrendingDestinationsBaseLocation]) => location);

          return this.locationsService
            .triggerLocationFetchAll(locationsUnder100.map((location) => location.locationCode))
            .pipe(
              map((locationsMap) =>
                locationsUnder100.map(
                  (location) =>
                    ({
                      ...location,
                      ...locationsMap[location.locationCode],
                    }) as TrendingDestinationLocation
                )
              )
            );
        }
      )
    );
  }
}
