import { Component, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';

import {
  ButtonMode,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  IconPosition,
  InputType,
  LoaderTheme,
  NotificationTheme,
} from '@fcom/ui-components';
import { loginHasError, loginPhase, loginStatus } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { AppState } from '@fcom/core/interfaces';
import { LoginActions } from '@fcom/core/actions';
import { LoginStatus, LoginStep, PostLostPasswordBy } from '@fcom/core-api/login';
import { unsubscribe } from '@fcom/core/utils';
import { LinkSize } from '@fcom/ui-components/components/link/enums';
import { ConfigService } from '@fcom/core/services';
import { LanguageService } from '@fcom/ui-translate';
import { RootPaths } from '@fcom/core/constants';

import { LoginService } from '../../services';
import { GtmService } from '../../../gtm';
import { ElementActions, ElementTypes, GaContext } from '../../../interfaces';
import { corporateUsernameValidator, usernameValidator } from '../../../utils/form-validators';
import { HotjarCaptureEvent, HotjarService, ScrollService } from '../../../services';

interface CorporateCredentials {
  username: FormControl<string>;
  password: FormControl<string>;
  keep: FormControl<boolean>;
}

@Component({
  selector: 'fin-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly LinkSize = LinkSize;
  readonly IconPosition = IconPosition;
  readonly LoaderTheme = LoaderTheme;
  readonly InputType = InputType;
  readonly LoginStep = LoginStep;
  readonly NotificationTheme = NotificationTheme;
  readonly PostLostPasswordBy = PostLostPasswordBy;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  mfaMsg = {
    title: 'login.2faTitle',
    description: 'login.smsVerificationCode',
    error: 'login.smsCodeError',
  };

  loginTranslations = {
    [LoginStep.CREDENTIALS]: {
      title: 'login.title',
      description: 'login.description',
      error: 'login.failed',
    },
    [LoginStep.CORPORATE_CREDENTIALS]: {
      title: 'login.b2bTitle',
      description: 'login.b2bDescription',
      error: 'login.b2bFailed',
    },
    [LoginStep.CORPORATE_CREDENTIALS_DIRECT]: {
      title: 'login.b2bTitle',
      description: 'login.b2bDescription',
      error: 'login.b2bFailed',
    },
    [LoginStep.TWO_FACTOR_CODE]: {
      title: 'login.2faTitle',
      description: 'login.2faDescription',
      error: 'login.2faError',
    },
    [LoginStep.TWO_FACTOR_PHONE]: this.mfaMsg,
    [LoginStep.TWO_FACTOR_SMS]: this.mfaMsg,
    [LoginStep.LOCKED]: {
      title: 'login.lockedTitle',
      description: 'login.lockedDescription',
    },
    [LoginStep.FORGOT_PASSWORD]: {
      title: 'login.forgotPassword.title',
      description: 'login.forgotPassword.description',
      error: 'login.forgotPassword.failed',
    },
    [LoginStep.POST_LOST_PASSWORD_SENT]: {
      title: 'login.forgotPassword.successTitle',
      description: 'login.forgotPassword.successDescription',
    },
  };

  @Input()
  fullScreen = false;

  subscriptions = new Subscription();
  reactiveForm: UntypedFormGroup;
  corporateCredentialsForm: FormGroup<CorporateCredentials>;
  authenticatorForm: UntypedFormGroup;
  smsCodeForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  corporateChangePasswordUrl: string;
  showError$: Observable<boolean>;
  loading$ = new Subject<boolean>();
  step$: Observable<LoginStep>;
  icon$: Observable<SvgLibraryIcon>;

  postLostPasswordError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private loginService: LoginService,
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private scrollService: ScrollService,
    private hotjarService: HotjarService,
    private configService: ConfigService,
    private languageService: LanguageService,
    private elementRef: ElementRef,
    private router: Router
  ) {
    this.step$ = this.store$.pipe(
      loginPhase(),
      map((phase) => phase?.step),
      distinctUntilChanged(),
      finShare()
    );
    this.icon$ = this.step$.pipe(
      map((step) => {
        switch (step) {
          case LoginStep.CREDENTIALS:
            return SvgLibraryIcon.FINNAIR_EMBLEM_BLOCK;
          case LoginStep.POST_LOST_PASSWORD_SENT:
            return SvgLibraryIcon.CHECKMARK_BLOCK;
          case LoginStep.CORPORATE_CREDENTIALS_DIRECT:
            return SvgLibraryIcon.B2B_BLOCK;
          default:
            return undefined;
        }
      })
    );
    const loginError$ = this.store$.pipe(
      loginHasError(),
      tap((hasError) => {
        if (hasError) {
          this.trackEvent('login-event', 'false');
        }
        this.loading$.next(false);
      }),
      startWith(false)
    );

    this.showError$ = combineLatest([loginError$, this.postLostPasswordError$.asObservable()]).pipe(
      map(([loginError, resetError]) => loginError || resetError)
    );
    this.subscriptions.add(
      this.store$
        .pipe(
          loginStatus(),
          filter((status) => status === LoginStatus.PENDING),
          take(1)
        )
        .subscribe(() => {
          this.trackEvent('login-event', 'true');
          this.store$.dispatch(LoginActions.clearLoginPhase());
        })
    );

    this.reactiveForm = this.fb.group({
      member: this.fb.control('', Validators.compose([Validators.required, usernameValidator()])),
      pwd: this.fb.control('', Validators.compose([Validators.required])),
      keep: this.fb.control(false, { nonNullable: true }),
    });
    this.corporateCredentialsForm = this.fb.group({
      username: ['', [Validators.required, corporateUsernameValidator()]],
      password: ['', [Validators.required]],
      keep: [false],
    });
    this.authenticatorForm = this.fb.group({
      code: this.fb.control('', Validators.compose([Validators.required])),
    });
    this.smsCodeForm = this.fb.group({
      code: this.fb.control('', Validators.compose([Validators.required])),
    });
    this.forgotPasswordForm = this.fb.group({
      postLostPasswordBy: this.fb.control(PostLostPasswordBy.EMAIL, [Validators.required]),
      member: this.fb.control('', [Validators.required, usernameValidator()]),
      lastName: this.fb.control('', [Validators.required]),
    });
    this.corporateChangePasswordUrl = `https://${this.configService.cfg.casHost}/content/${this.languageService.localeValue}/login/corporate/forgot-password`;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  forgotPassword(): void {
    this.trackEvent('forgot-password');
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.FORGOT_PASSWORD }));
  }

  postLostPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.loading$.next(true);

    this.postLostPasswordError$.next(false);
    const { postLostPasswordBy, member, lastName } = this.forgotPasswordForm.getRawValue();

    this.trackEvent('reset-password');

    this.subscriptions.add(
      this.loginService.postLostPassword(postLostPasswordBy, member, lastName).subscribe({
        next: (res) => {
          this.loading$.next(false);
          if (res?.message?.[0]?.severity === 'ERROR') {
            this.postLostPasswordError$.next(true);
          } else {
            this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.POST_LOST_PASSWORD_SENT }));
          }
        },
        error: () => {
          this.loading$.next(false);
          this.postLostPasswordError$.next(true);
        },
      })
    );
  }

  login(): void {
    if (this.reactiveForm.invalid) {
      this.reactiveForm.markAllAsTouched();
      return this.scrollService.scrollToFirstInvalidInput(this.elementRef);
    }
    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.trackEvent('submit-login');
    const { member, pwd, keep } = this.reactiveForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login(member, pwd, keep);
  }

  corporateLogin(): void {
    if (this.corporateCredentialsForm.invalid) {
      this.corporateCredentialsForm.markAllAsTouched();
      return this.scrollService.scrollToFirstInvalidInput(this.elementRef);
    }
    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.trackEvent('submit-corporate-login');
    const { username, password, keep } = this.corporateCredentialsForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login(username, password, keep);
    this.subscriptions.add(this.hotjarService.startCapture(HotjarCaptureEvent.CORPORATE_LOGIN).subscribe());
    this.redirectToCorporatePortal();
  }

  login2fa(): void {
    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.trackEvent('login-2fa');
    const { code } = this.authenticatorForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login2fa(code);
  }

  requestSMS(): void {
    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.trackEvent('request-sms-login');
    this.loginService.requestSMS();
  }

  loginSms(requestedSms: boolean): void {
    this.store$.dispatch(LoginActions.setLoginPhaseError({ hasError: false }));
    this.trackEvent('login-sms');
    const { code } = this.smsCodeForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login2fa(code, requestedSms);
  }

  openCorporateLoginForm(): void {
    const { member, pwd } = this.reactiveForm.getRawValue();
    const formState = member || pwd ? 'form-filled' : 'form-empty';
    this.trackEvent('corporate-login', formState);
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CORPORATE_CREDENTIALS }));
  }

  closeDialog(): void {
    this.trackEvent('close');
    this.store$.dispatch(LoginActions.clearLoginPhase());
  }

  resetLogin(event: Event): void {
    event.stopPropagation();
    this.loading$.next(false);
    this.reactiveForm.reset();
    this.authenticatorForm.reset();
    this.smsCodeForm.reset();
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
  }

  trackAndCloseDialog(): void {
    this.closeDialog();
    this.trackEvent('login-read-more-about-corporate');
  }

  redirectToCorporatePortal(): void {
    this.subscriptions.add(
      this.loginService.loginTrigger$.pipe(take(1)).subscribe(() => {
        if (!this.router.url.includes(RootPaths.CORPORATE)) {
          this.closeDialog();
          this.scrollService.scrollTop();
          this.router.navigate([this.languageService.langValue, RootPaths.CORPORATE, 'home']);
        }
      })
    );
  }

  private trackEvent(elementName: string, state: string = undefined): void {
    this.gtmService.trackElement(
      `login-form-${elementName}`,
      GaContext.FINNAIR_PLUS,
      ElementTypes.BUTTON,
      state,
      ElementActions.CLICK
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent): void {
    this.closeDialog();
  }
}
