import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { asPaxTypeKey } from '@fcom/dapi/utils';
import { FinnairFrequentFlyerCardItem, FinnairPassengerCode, FinnairPassengerItem } from '@fcom/dapi/api/models';
import { FREQUENT_FLIER_AIRLINES } from '@fcom/core/utils';
import { ButtonTheme, IconPosition } from '@fcom/ui-components';

import { FrequentFlyerTierLevel } from '../../interfaces/utils.interface';

@Component({
  selector: 'fin-passenger-info',
  templateUrl: 'passenger-info.component.html',
  styleUrls: ['./passenger-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerInfoComponent implements OnInit, OnChanges {
  readonly ButtonTheme = ButtonTheme;
  readonly FinnairPassengerCode = FinnairPassengerCode;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  passenger: FinnairPassengerItem;

  @Input()
  accompanyingAdult: FinnairPassengerItem;

  @Input()
  accompanyingInfant: FinnairPassengerItem;

  @Input()
  showEdit = false;

  @Output()
  edit: EventEmitter<void> = new EventEmitter();

  extendedPassenger: FinnairPassengerItem & {
    paxTypeKey: string;
    cards: (FinnairFrequentFlyerCardItem & { translationKey: string })[];
  };

  ngOnInit(): void {
    this.updatePassenger();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passenger']) {
      this.updatePassenger();
    }
  }

  private updatePassenger(): void {
    this.extendedPassenger = {
      ...this.passenger,
      paxTypeKey: asPaxTypeKey(this.passenger.passengerTypeCode).toLowerCase(),
      cards: (this.passenger.frequentFlyerCards ?? []).map((card) => ({
        ...card,
        translationKey: this.getFrequentFlyerLabel(card),
      })),
    };
  }

  private getFrequentFlyerLabel(card: FinnairFrequentFlyerCardItem): string {
    const companyCode = card.companyCode.toLowerCase();
    const tierBaseLabel = 'finnairPlus.tier';
    const companyBaseLabel = 'purchase.review.frequentFlyer';

    if (companyCode !== 'ay') {
      if (FREQUENT_FLIER_AIRLINES.some(({ code }) => code.toLowerCase() === companyCode)) {
        return `${companyBaseLabel}.${companyCode}`;
      }
      return `${companyBaseLabel}.default`;
    }

    switch (card.tierLevel) {
      case FrequentFlyerTierLevel.PLUS:
        return `${tierBaseLabel}.basic`;
      case FrequentFlyerTierLevel.SILVER:
        return `${tierBaseLabel}.silver`;
      case FrequentFlyerTierLevel.GOLD:
        return `${tierBaseLabel}.gold`;
      case FrequentFlyerTierLevel.PLATINUM:
        return `${tierBaseLabel}.platinum`;
      case FrequentFlyerTierLevel.LUMO:
        return `${tierBaseLabel}.lumo`;
      case FrequentFlyerTierLevel.JUNIOR:
        return `${tierBaseLabel}.junior`;
      default:
        return `${companyBaseLabel}.ay`;
    }
  }
}
