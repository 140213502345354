import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { catchError, map, startWith, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ConfigService, SentryLogger } from '@fcom/core/services';
import { AvailabilityEntry, LowestPriceOfPeriod } from '@fcom/dapi/api/models';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { AppState, GlobalBookingTravelClass, isPresent, LocalDate, mapErrorForSentry, unsubscribe } from '@fcom/core';
import { InstantsearchService } from '@fcom/dapi/api/services';
import { LanguageService } from '@fcom/ui-translate';
import { DatePickerPrices, ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { Amount } from '@fcom/dapi/interfaces';
import { Location } from '@fcom/core-api/interfaces';
import { CmsTripTypeMap } from '@fcom/core/utils/tripType.utils';

import {
  BasePriceCalendarParams,
  CalendarPrices,
  GlobalBookingTripDates,
  HistogramBar,
  InstantSearchBaseParams,
  InstantSearchFollowingMonthParams,
  InstantSearchFullYearParams,
  PriceCalendarCTAParams,
  PriceCalendarParams,
  PriceCalendarWithPricesParams,
  TripType,
} from '../../interfaces';
import {
  createHistogramData,
  getStartingFromPrice,
  isPriceCalendarWithPricesParams,
  mapInstantSearchPricesToCalendarPrices,
  priceCalendarBaseParamsAreValid,
} from '../../utils';
import { GlobalBookingActions, GlobalBookingFlight } from '../../store';
import { GtmService } from '../../gtm';
import { createDeeplinkPathFromFlightSearchParams } from '../../utils/flight-search.utils';

const DAYS_YEAR = 360;
const DAYS_MONTH = 30;

@Injectable()
export class PriceCalendarService implements OnDestroy {
  static NUMBER_OF_RETRIES = 2;

  private readonly _startingFromPrice$: BehaviorSubject<Amount> = new BehaviorSubject(undefined);
  private readonly _fullYearPrices$: BehaviorSubject<DatePickerPrices> = new BehaviorSubject({
    calendar: undefined,
    histogram: undefined,
  });
  private readonly _calendarPrices$: Observable<DatePickerPrices> = of(undefined);
  private _followingMonthPrices$: BehaviorSubject<CalendarPrices> = new BehaviorSubject({});
  private _travelDates$ = new BehaviorSubject<GlobalBookingTripDates>({
    departureDate: undefined,
    returnDate: undefined,
  });

  private _params$: BehaviorSubject<PriceCalendarParams> = new BehaviorSubject<PriceCalendarParams>(undefined);

  private _isLoading$: Observable<boolean>;

  private subscriptions = new Subscription();

  private getPricesForFullYearRequestsCache = new Map<
    string,
    Observable<{
      fullYear: CalendarPrices;
      histogram: HistogramBar[];
      availability?: AvailabilityEntry[];
    }>
  >();
  service: Observable<PriceCalendarWithPricesParams>;
  calendarOpen$ = new Subject<PriceCalendarParams | null>();

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService,
    private instantsearchService: InstantsearchService,
    private sentryLogger: SentryLogger,
    private appStore$: Store<AppState>,
    private gtmService: GtmService,
    private router: Router
  ) {
    this._calendarPrices$ = combineLatest([
      this._params$.pipe(filter(isPresent)),
      this._fullYearPrices$,
      this._followingMonthPrices$,
      this._travelDates$,
    ]).pipe(
      map(([{ tripType }, fullYearPrices, followingMonth, { departureDate, returnDate }]) => {
        const oneway = tripType === TripType.ONEWAY;
        const onlyDepartureSelectedAndNotOneway = isPresent(departureDate) && !oneway && !isPresent(returnDate);
        const prices = onlyDepartureSelectedAndNotOneway ? followingMonth : fullYearPrices?.calendar;

        const pricesWithSelections =
          departureDate && returnDate ? { ...prices, [returnDate?.id]: followingMonth?.[returnDate?.id] } : prices;
        return { calendar: pricesWithSelections, histogram: fullYearPrices?.histogram };
      }),
      finShare()
    );

    this.subscriptions.add(
      combineLatest([this._calendarPrices$, this._params$.pipe(filter(isPresent)), this._travelDates$])
        .pipe(
          map(([prices, { tripType }, travelDates]) => getStartingFromPrice(prices, tripType, travelDates)),
          startWith(undefined)
        )
        .subscribe((val) => this._startingFromPrice$.next(val))
    );

    this.subscriptions.add(
      this._params$
        .pipe(
          filter(isPresent),
          filter((params) => isPriceCalendarWithPricesParams(params)),
          switchMap((params: PriceCalendarWithPricesParams) => this.getPricesForFullYear(params)),
          map(({ fullYear, histogram }) => ({ calendar: fullYear, histogram })),
          finShare()
        )
        .subscribe((val) => this._fullYearPrices$.next(val))
    );

    this.subscriptions.add(
      combineLatest([this._params$, this._travelDates$])
        .pipe(
          filter(
            ([params, travelDates]: [PriceCalendarWithPricesParams, GlobalBookingTripDates]) =>
              isPresent(params) && isPresent(travelDates.departureDate?.id)
          ),
          distinctUntilChanged(([prevParams, prevTravelDates], [nextParams, nextTravelDates]) => {
            const departureDateNotChanged = nextTravelDates.departureDate?.id === prevTravelDates.departureDate?.id;
            const locationsNotChanged =
              nextParams.origin === prevParams.origin && nextParams.destination === prevParams.destination;
            const tripTypeChanged = prevParams.tripType === nextParams.tripType;
            const travelClassChanged = prevParams.travelClass === nextParams.travelClass;
            return departureDateNotChanged && locationsNotChanged && tripTypeChanged && travelClassChanged;
          }),
          filter(([params]) => isPriceCalendarWithPricesParams(params)),
          switchMap(([params, travelDates]: [PriceCalendarWithPricesParams, GlobalBookingTripDates]) =>
            this.getPricesForFollowingMonth(params, travelDates.departureDate)
          ),
          finShare()
        )
        .subscribe((value) => this._followingMonthPrices$.next(value))
    );

    this._isLoading$ = this.fullYearPrices$.pipe(
      map(({ calendar, histogram }) => !isPresent(calendar) && !isPresent(histogram)),
      finShare()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  set params$(params: BasePriceCalendarParams) {
    if (isPresent(params) && priceCalendarBaseParamsAreValid(params)) {
      this._params$.next(params);
      if (params.initialTravelDates) {
        this._travelDates$.next(params.initialTravelDates);
      }
    }
  }

  get params$(): Observable<PriceCalendarParams> {
    return this._params$.asObservable().pipe(finShare());
  }

  get tripType$(): Observable<TripType> {
    return this._params$.pipe(
      filter(isPresent),
      map(({ tripType }) => tripType),
      finShare()
    );
  }

  get travelDates$(): Observable<GlobalBookingTripDates> {
    return this._travelDates$.asObservable().pipe(finShare());
  }

  get travelDatesValues(): GlobalBookingTripDates {
    return this._travelDates$.getValue();
  }

  get startingFromPrice$(): Observable<Amount> {
    return this._startingFromPrice$.pipe(finShare());
  }

  get fullYearPrices$(): Observable<DatePickerPrices> {
    return this._fullYearPrices$.asObservable().pipe(finShare());
  }

  get calendarPrices$(): Observable<DatePickerPrices> {
    return this._calendarPrices$;
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.pipe(finShare());
  }

  convertToReturnTrip(): void {
    this._fullYearPrices$.next({ calendar: undefined, histogram: undefined });

    const currentParams = this._params$.getValue();
    this._params$.next({
      ...currentParams,
      tripType: TripType.RETURN,
    });
  }

  getPriceCalendarCTAParams(): PriceCalendarCTAParams {
    const params = this._params$.getValue();
    const { tripType, destination, origin } = params;

    const { departureDate, returnDate } = this._travelDates$.getValue();
    const departureFlight: GlobalBookingFlight = {
      origin: { locationCode: origin } as Location,
      destination: { locationCode: destination } as Location,
      departureDate,
    };
    const returnFlight: GlobalBookingFlight = {
      origin: { locationCode: destination } as Location,
      destination: { locationCode: origin } as Location,
      departureDate: returnDate,
    };

    const flights: GlobalBookingFlight[] =
      tripType === TripType.RETURN ? [departureFlight, returnFlight] : [departureFlight];

    return {
      flights,
      tripType: tripType,
      ...(isPriceCalendarWithPricesParams(params) && {
        paxAmount: params.paxAmount,
        travelClass: params.travelClass,
      }),
    };
  }

  resetCalendar(): void {
    this._fullYearPrices$.next({ calendar: undefined, histogram: undefined });
    this._travelDates$.next({ departureDate: undefined, returnDate: undefined });
    this._params$.next(undefined);
  }

  getPricesForFullYear(
    params: PriceCalendarWithPricesParams
  ): Observable<{ fullYear: CalendarPrices; histogram: HistogramBar[]; availability?: AvailabilityEntry[] }> {
    const serializedParams = JSON.stringify(params);

    if (this.getPricesForFullYearRequestsCache.has(serializedParams)) {
      return this.getPricesForFullYearRequestsCache.get(serializedParams)!;
    }

    const fullYearParams = this.convertToFullYearParams(params);
    const request$ = this.instantsearchService
      .getPricesForPeriod(this.configService.cfg.instantSearchUrl, fullYearParams)
      .pipe(
        withLatestFrom(this.languageService.translate('date')),
        map(([response, dateTranslations]: [LowestPriceOfPeriod, Record<string, string>]) => {
          const fullYear = mapInstantSearchPricesToCalendarPrices(response);
          const histogram = createHistogramData(response, Object.keys(fullYear), dateTranslations);

          return {
            fullYear,
            histogram,
            availability: response?.availability,
          };
        }),
        retryWithBackoff(PriceCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('Error finding prices for period', {
            error: mapErrorForSentry(err),
          });
          return of({ fullYear: {}, histogram: [] });
        }),
        finalize(() => {
          this.getPricesForFullYearRequestsCache.delete(serializedParams);
        }),
        finShare()
      );
    this.getPricesForFullYearRequestsCache.set(serializedParams, request$);

    return request$;
  }

  getPricesForFollowingMonth(
    params: PriceCalendarWithPricesParams,
    departureDate: LocalDate
  ): Observable<CalendarPrices> {
    const followingMonthParams = this.convertToFollowingMonthParams(params, departureDate);
    return this.instantsearchService
      .getPricesForPeriodWithFixedDepartureDate(this.configService.cfg.instantSearchUrl, followingMonthParams)
      .pipe(
        map((response: LowestPriceOfPeriod) => mapInstantSearchPricesToCalendarPrices(response)),
        retryWithBackoff(PriceCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('Error finding prices for following month', {
            error: mapErrorForSentry(err),
          });
          return of({});
        }),
        finShare()
      );
  }

  updateCalendarDates(calendarDates: GlobalBookingTripDates): void {
    this._travelDates$.next(calendarDates);
  }

  openPriceCalendar(
    originLocationCode: string,
    destinationLocationCode: string,
    tripType: TripType,
    travelClass: GlobalBookingTravelClass,
    destinationTitle: string
  ): void {
    this.calendarOpen$.next({
      origin: originLocationCode,
      destination: destinationLocationCode,
      tripType,
      travelClass,
      paxAmount: {
        adults: 1,
        c15s: 0,
        children: 0,
        infants: 0,
      },
    });
    this.gtmService.trackElement(
      `tile-${destinationTitle.replace(/ /g, '_')}-${travelClass}-${CmsTripTypeMap[tripType]}`,
      `${GaContext.DESTINATION_MAIN_PAGE}_open-dates`,
      ElementTypes.LIST_ITEM,
      undefined,
      ElementActions.CLICK
    );
  }

  priceCalendarCTAClicked(params: PriceCalendarCTAParams): void {
    this.prefillBookingWidget(
      params.travelClass,
      { departureDate: params.flights[0].departureDate, returnDate: params.flights[1]?.departureDate },
      params.tripType,
      params.flights[0].destination.locationCode,
      params.flights[0].origin.locationCode
    );

    const deeplink = createDeeplinkPathFromFlightSearchParams({
      flights: params.flights,
      tripType: params.tripType,
      paxAmount: params.paxAmount,
      travelClass: params.travelClass,
      locale: this.languageService.langValue,
    });
    this.router.navigateByUrl(deeplink);
  }

  closePriceCalendar(): void {
    this.calendarOpen$.next(null);
  }

  prefillBookingWidget(
    travelClass: GlobalBookingTravelClass,
    selectedDates: {
      departureDate: LocalDate | undefined;
      returnDate: LocalDate | undefined;
    },
    tripType: TripType,
    destinationLocationCode: string,
    selectedOriginLocationCode: string
  ): void {
    const departureFlight = {
      origin: { locationCode: selectedOriginLocationCode } as Location,
      destination: { locationCode: destinationLocationCode } as Location,
      departureDate: selectedDates.departureDate,
    };
    const returnFlight = {
      origin: { locationCode: destinationLocationCode } as Location,
      destination: { locationCode: selectedOriginLocationCode } as Location,
      departureDate: selectedDates.returnDate,
    };

    const flights: GlobalBookingFlight[] =
      tripType === TripType.RETURN ? [departureFlight, returnFlight] : [departureFlight];

    this.appStore$.dispatch(GlobalBookingActions.setFlights({ flights }));
    this.appStore$.dispatch(GlobalBookingActions.setTravelClass({ travelClass }));
    this.appStore$.dispatch(GlobalBookingActions.setOrigin({ origin: selectedOriginLocationCode }));
    this.appStore$.dispatch(GlobalBookingActions.setTripType({ tripType }));
  }

  private getCommonParams({
    origin,
    destination,
    paxAmount,
    travelClass,
  }: PriceCalendarWithPricesParams): Omit<InstantSearchBaseParams, 'numberOfDays'> {
    return {
      departureLocationCode: origin,
      destinationLocationCode: destination,
      adults: paxAmount.adults + paxAmount.c15s,
      children: paxAmount.children,
      infants: paxAmount.infants,
      locale: this.languageService.localeValue,
      travelClass: travelClass === GlobalBookingTravelClass.MIXED ? null : travelClass,
    };
  }

  private convertToFollowingMonthParams(
    params: PriceCalendarWithPricesParams,
    departureDate: LocalDate
  ): InstantSearchFollowingMonthParams {
    const commonParams = this.getCommonParams(params);
    return { ...commonParams, numberOfDays: DAYS_MONTH, departureDate: departureDate.id };
  }

  private convertToFullYearParams(params: PriceCalendarWithPricesParams): InstantSearchFullYearParams {
    const commonParams = this.getCommonParams(params);
    return {
      ...commonParams,
      oneway: params.tripType === TripType.ONEWAY,
      startDate: LocalDate.now().id,
      numberOfDays: DAYS_YEAR,
    };
  }
}
