<div
  fcomTrapFocus
  role="dialog"
  [attr.aria-label]="'chat.preChatAriaLabel' | finLocalization"
  class="chatPosition fixed chatWindow white-bg"
>
  <div class="chatWrapper relative">
    <header class="chatHeader grid-col-12 no-row-gap grid-max-width white-bg">
      <div class="col-f">
        <div class="fill flex ps-small-y">
          <div class="flex-1 flex--basis0 flex flex--start flex--self-center"></div>

          <div class="flex-1 flex flex--center flex--self-center">
            <fcom-icon
              class="finnair-emblem"
              [category]="IconLibrary.CUSTOM"
              [name]="CustomIcon.FINNAIR_EMBLEM"
            ></fcom-icon>
          </div>

          <div class="flex-1 flex--basis0 flex flex--end flex--self-center align-right">
            <button
              (click)="chatToggle.emit(HelpButtonStatus.VISIBLE)"
              [attr.title]="'actions.minimize' | finLocalization"
              [attr.aria-label]="'actions.minimize' | finLocalization"
              class="link-hover ms-small-r focus-inset"
              #minimize
            >
              <fcom-icon
                [dataTestId]="'icon-' + SvgLibraryIcon.MINIMISE_CHAT"
                [name]="SvgLibraryIcon.MINIMISE_CHAT"
              ></fcom-icon>
            </button>
            <button
              (click)="chatToggle.emit(HelpButtonStatus.VISIBLE)"
              [attr.title]="'actions.close' | finLocalization"
              [attr.aria-label]="'actions.close' | finLocalization"
              class="link-hover focus-inset"
            >
              <fcom-icon
                [dataTestId]="'icon-' + SvgLibraryIcon.CLOSE_DELETE"
                [name]="SvgLibraryIcon.CLOSE_DELETE"
              ></fcom-icon>
            </button>
          </div>
        </div>
      </div>
    </header>
    <div *ngIf="!startUserForm" class="chatBody pre-chat-body">
      <h3>{{ 'chat.welcomeMessage' | finLocalization }}</h3>

      <p class="font-body-1 light-type pr-medium-b pr-xsmall-t">
        {{ 'chat.subtitle' | finLocalization }}
        <a
          *ngIf="(loginStatus$ | async) === LoginStatus.NOT_LOGGED_IN"
          data-testid="chat-login-link"
          class="bold-type pre-chat-link"
          href="#"
          (click)="handleLogin($event)"
        >
          {{ 'chat.subtitleLoginText' | finLocalization }}
        </a>
      </p>

      <fcom-button
        *ngIf="!usePreChatForm"
        [text]="'chat.buttonHeading' | finLocalization"
        [icon]="{ name: SvgLibraryIcon.CHAT, position: IconPosition.LEFT }"
        [mode]="ButtonMode.FILL"
        (clickBtn)="startChat.emit()"
        data-testid="chat-start-chat"
        [title]="'actions.startChat' | finLocalization"
        [aria]="{ label: 'actions.startChat' | finLocalization }"
      ></fcom-button>

      <fcom-button
        *ngIf="usePreChatForm"
        data-testid="user-chat-form-start-btn"
        [text]="'chat.buttonHeading' | finLocalization"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.CHAT, position: IconPosition.LEFT }"
        [mode]="ButtonMode.FILL"
        (clickBtn)="startUserForm = !startUserForm"
        [title]="'actions.startChat' | finLocalization"
        [aria]="{ label: 'actions.startChat' | finLocalization }"
      ></fcom-button>

      <fin-expand
        *ngFor="let item of availableAccordions$ | async; let index = index"
        [id]="chatExpandKey + '-' + index"
        [key]="chatExpandKey"
        [clearSelectionOnDestroy]="true"
        class="block"
        [ngClass]="index === 0 ? 'ps-medium-t' : ''"
        [finElemTracker]="chatExpandKey + '-' + index"
        [finElemContext]="GaContext.CHAT"
        [finElemType]="ElementTypes.ACCORDION"
        #expand
      >
        <div class="trigger flex flex--center ps-small-t ps-small-b">
          <div class="flex flex-1 flex--middle mr-xsmall-r">
            <span class="font-heading-5 bold-type" [innerHTML]="item.teaserTitle"></span>
          </div>
          <fcom-icon
            [dataTestId]="'icon-' + SvgLibraryIcon.CHEVRON_DOWN"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
            class="flex-1 flex--nogrow rotate icon-medium"
            [class.rotate-active]="expand.isOpen$ | async"
          ></fcom-icon>
        </div>
        <div class="content">
          <div *ngIf="expand.isOpen$ | async" class="font-body-1 pr-small-t" [innerHTML]="item.teaserText"></div>
        </div>
      </fin-expand>
    </div>

    <fin-chat-user-form *ngIf="startUserForm"></fin-chat-user-form>
  </div>
</div>
