<fin-login-form-container
  [fullScreen]="fullScreen"
  *finLet="step$ | async as step"
  (returnClicked)="resetLogin($event)"
  (closeClicked)="closeDialog()"
  [title]="loginTranslations[step].title"
  [description]="loginTranslations[step].description"
  [error]="(showError$ | async) ? loginTranslations[step].error : undefined"
  [compact]="
    step === LoginStep.CREDENTIALS ||
    step === LoginStep.CORPORATE_CREDENTIALS ||
    step === LoginStep.CORPORATE_CREDENTIALS_DIRECT
  "
  [icon]="icon$ | async"
  data-hj-suppress
>
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="LoginStep.CREDENTIALS">
      <form (submit)="login()" [formGroup]="reactiveForm" class="credentials">
        <div class="ps-medium-x">
          <fcom-text-input
            [translationPrefix]="'login.fields.member'"
            [parentForm]="reactiveForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'member'"
            [placeHolder]="'login.memberExample'"
          ></fcom-text-input>
          <fcom-text-input
            [translationPrefix]="'login.fields.password'"
            [parentForm]="reactiveForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'pwd'"
            [isPassword]="true"
          ></fcom-text-input>

          <button
            type="button"
            class="underline nordic-blue-900-text fill align-right ms-small-y"
            [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'"
            (click)="forgotPassword()"
          >
            {{ 'login.forgot' | finLocalization }}
          </button>

          <fcom-checkbox [name]="'keep'" [formControlName]="'keep'" [cid]="'keep'">
            <span [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'">{{
              'login.keepLoggedIn' | finLocalization
            }}</span>
          </fcom-checkbox>
        </div>
        <div class="button-container white-bg">
          <div class="ps-medium">
            <fcom-button
              [type]="ButtonType.SUBMIT"
              data-testid="login-form-login-button"
              [loading]="loading$ | async"
              [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
              [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
              [icon]="
                (loading$ | async)
                  ? {
                      name: SvgLibraryIcon.TRANSFER_LOADER,
                      position: IconPosition.RIGHT,
                    }
                  : null
              "
              [mode]="ButtonMode.FILL"
              [text]="'login.login' | finLocalization"
              class="ms-large-t ms-small-b"
            ></fcom-button>
          </div>
          <div class="nordic-blue-25-bg ps-medium-x ps-small-y">
            <div class="ms-medium-b flex">
              <div class="ms-xsmall-r">
                <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.B2B_BLOCK"></fcom-icon>
              </div>
              <div>
                <span class="font-heading-5">{{ 'login.b2bTitle' | finLocalization }}</span>
              </div>
            </div>
            <fcom-button
              (clickBtn)="openCorporateLoginForm()"
              [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
              [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
              [mode]="ButtonMode.FILL"
              [text]="'login.corporateLogin' | finLocalization"
              [icon]="{
                name: SvgLibraryIcon.ARROW_RIGHT,
                position: IconPosition.RIGHT,
              }"
            ></fcom-button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.CORPORATE_CREDENTIALS">
      <ng-container *ngTemplateOutlet="corporateLoginForm"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.CORPORATE_CREDENTIALS_DIRECT">
      <ng-container *ngTemplateOutlet="corporateLoginForm"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.FORGOT_PASSWORD">
      <form (submit)="postLostPassword()" [formGroup]="forgotPasswordForm">
        <div class="ms-medium-b">
          <fcom-radio-item-group
            [options]="[{ value: PostLostPasswordBy.EMAIL }, { value: PostLostPasswordBy.SMS }]"
            [parentForm]="forgotPasswordForm"
            [controlName]="'postLostPasswordBy'"
            [isSensitive]="true"
            [translationPrefix]="'login.forgotPassword.fields.postLostPasswordBy'"
          >
          </fcom-radio-item-group>
        </div>
        <fcom-text-input
          [translationPrefix]="'login.forgotPassword.fields.member'"
          [parentForm]="forgotPasswordForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'member'"
          [placeHolder]="'login.memberExample'"
          class="block ms-medium-b"
        ></fcom-text-input>
        <fcom-text-input
          [translationPrefix]="'login.forgotPassword.fields.lastName'"
          [parentForm]="forgotPasswordForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'lastName'"
        ></fcom-text-input>

        <fcom-button
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!forgotPasswordForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.forgotPassword.submit' | finLocalization"
          class="ms-large-t"
        ></fcom-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.POST_LOST_PASSWORD_SENT">
      <fcom-button
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [icon]="{ name: SvgLibraryIcon.SINGLE_PAX, position: IconPosition.LEFT }"
        [mode]="ButtonMode.FILL"
        [text]="'login.login' | finLocalization"
        (clickBtn)="resetLogin($event)"
      >
      </fcom-button>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.TWO_FACTOR_CODE">
      <form (submit)="login2fa()" [formGroup]="authenticatorForm">
        <fcom-text-input
          [displayMargin]="false"
          [required]="false"
          [translationPrefix]="'login.fields.2faCode'"
          [parentForm]="authenticatorForm"
          [controlName]="'code'"
          [isPassword]="true"
        ></fcom-text-input>

        <fcom-button
          class="ms-small-y"
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!authenticatorForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
        ></fcom-button>
      </form>

      <p [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'" class="ms-small-y">
        {{ 'login.2faRequestCode' | finLocalization }}
      </p>
      <fcom-button
        [loading]="loading$ | async"
        [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [mode]="ButtonMode.FILL"
        [text]="'login.2faRequestCodeBtn' | finLocalization"
        (clickBtn)="requestSMS()"
      ></fcom-button>
    </ng-container>

    <ng-container *ngSwitchCase="[LoginStep.TWO_FACTOR_PHONE, LoginStep.TWO_FACTOR_SMS].includes(step) ? step : null">
      <form (submit)="loginSms(step === LoginStep.TWO_FACTOR_PHONE)" [formGroup]="smsCodeForm">
        <fcom-text-input
          [displayMargin]="false"
          [required]="false"
          [translationPrefix]="'login.fields.2faSmsCode'"
          [parentForm]="smsCodeForm"
          [controlName]="'code'"
          [isPassword]="true"
        ></fcom-text-input>

        <fcom-button
          class="ms-small-y"
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!smsCodeForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
        ></fcom-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.LOCKED">
      <fcom-button
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [mode]="ButtonMode.FILL"
        [text]="'login.lockedCta' | finLocalization"
        (clickBtn)="forgotPassword()"
      ></fcom-button>
    </ng-container>
  </ng-container>
</fin-login-form-container>

<ng-template #corporateLoginForm>
  <form (submit)="corporateLogin()" [formGroup]="corporateCredentialsForm" class="credentials">
    <div class="ps-medium-x">
      <fcom-text-input
        [translationPrefix]="'login.fields.corporateMember'"
        [parentForm]="corporateCredentialsForm"
        [displayMargin]="false"
        [required]="false"
        [controlName]="'username'"
      ></fcom-text-input>
      <fcom-text-input
        [translationPrefix]="'login.fields.password'"
        [parentForm]="corporateCredentialsForm"
        [displayMargin]="false"
        [required]="false"
        [controlName]="'password'"
        [isPassword]="true"
      ></fcom-text-input>

      <div class="align-right ms-small-y">
        <fcom-link
          [size]="fullScreen ? LinkSize.MEDIUM : LinkSize.SMALL"
          [href]="corporateChangePasswordUrl"
          [external]="true"
        >
          {{ 'login.forgot' | finLocalization }}
        </fcom-link>
      </div>

      <fcom-checkbox [name]="'keep'" [formGroup]="corporateCredentialsForm" [formControlName]="'keep'" [cid]="'keep'">
        <span [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'">{{ 'login.keepLoggedIn' | finLocalization }}</span>
      </fcom-checkbox>
    </div>
    <div class="button-container white-bg ps-medium-t">
      <div class="nordic-blue-25-bg ps-medium-x ps-small-y">
        <fcom-button
          [type]="ButtonType.SUBMIT"
          data-testid="login-form-corporate-login-button"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
        ></fcom-button>
        <div class="ps-small-t align-center">
          <fcom-link
            [href]="'fragments.readMoreAboutCorporatePortal.url' | finLocalization"
            (beforeNavigation)="trackAndCloseDialog()"
          >
            {{ 'login.b2bReadMore' | finLocalization }}
          </fcom-link>
        </div>
      </div>
    </div>
  </form>
</ng-template>
