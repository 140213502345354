<ng-container *finLet="isJunior$ | async as isJunior">
  <fin-login-form-container
    [fullScreen]="fullScreen"
    [invertHeaderColors]="currentStep !== JoinFlowStep.JOIN_COMPLETE"
    [icon]="currentStep === JoinFlowStep.JOIN_COMPLETE ? SvgLibraryIcon.CHECKMARK_BLOCK : undefined"
    (closeClicked)="closeDialog()"
    [stepperSteps$]="stepperSteps$"
    [stepperActiveStep$]="stepperIndex$"
    [showLeftIcon]="
      enableAccountCreationVerification &&
      [JoinFlowStep.CONTACT_DETAILS, JoinFlowStep.GUARDIAN_DETAILS, JoinFlowStep.MARKETING_CONSENT].includes(
        currentStep$ | async
      )
    "
    (stepperClicked)="openStep($event)"
    (returnClicked)="goBack($event)"
    [title]="(stepTranslations$ | async).title"
    [description]="(stepTranslations$ | async).description"
    [overlay]="enableAccountCreationVerification"
    *ngIf="(phonePrefixes$ | async) && currentStep$ | async as currentStep"
    data-hj-suppress
  >
    <div class="header-content">
      <ng-container *ngIf="currentStep === JoinFlowStep.BASIC_INFO">
        <div
          class="font-body-1 flex flex-1 flex--top ms-xsmall-r ms-xsmall-b"
          *ngFor="let promote of ['earn', 'benefits', 'offers']"
        >
          <fcom-icon class="icon-medium ps-xsmall-r" [name]="SvgLibraryIcon.CHECKMARK" />
          <span class="fill">{{ 'login.join.promote.' + promote | finLocalization }}</span>
        </div>
      </ng-container>

      <p class="ps-medium-y" *ngIf="isJunior && currentStep === JoinFlowStep.CONTACT_DETAILS">
        {{ 'login.join.juniorInfo' | finLocalization }}
      </p>
    </div>

    <ng-container [ngSwitch]="currentStep">
      <ng-container *ngSwitchCase="JoinFlowStep.BASIC_INFO">
        <form (submit)="moveToStep(JoinFlowStep.CONTACT_DETAILS)" [formGroup]="basicInfoForm" class="white-bg">
          <fcom-text-input
            [translationPrefix]="'login.join.fields.firstName'"
            [parentForm]="basicInfoForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'firstName'"
          ></fcom-text-input>
          <fcom-text-input
            [translationPrefix]="'login.join.fields.lastName'"
            [parentForm]="basicInfoForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'lastName'"
          ></fcom-text-input>

          <span class="font-body-1 regular-type nordic-blue-400-text" [attr.id]="'select-group-label-dob'">
            {{ 'login.join.fields.dateOfBirth.label' | finLocalization }}
          </span>

          <fin-form-group
            class="block"
            [groupTranslationPrefix]="'login.join.fields.dateOfBirth'"
            [group]="basicInfoFormBirthDate"
            [names]="['dateOfBirth', 'monthOfBirth', 'yearOfBirth']"
          >
            <div class="flex">
              <fin-select-input
                data-testid="form-field-dateOfBirth"
                class="flex-1 flex--basis0 mr-xsmall-r"
                [controlName]="'dateOfBirth'"
                [parentForm]="basicInfoFormBirthDate"
                [options]="availableDays$ | async"
                [displayMargin]="false"
                [disableLabel]="true"
                [translationPrefix]="'login.join.fields.dateOfBirth'"
                [selectLabelTranslationKey]="'login.join.daySelect'"
              >
              </fin-select-input>

              <fin-select-input
                data-testid="form-field-monthOfBirth"
                class="flex-1 flex--basis0 mr-xsmall-r"
                [controlName]="'monthOfBirth'"
                [parentForm]="basicInfoFormBirthDate"
                [options]="availableMonths$ | async"
                [displayMargin]="false"
                [disableLabel]="true"
                [translationPrefix]="'login.join.fields.monthOfBirth'"
                [selectLabelTranslationKey]="'login.join.monthSelect'"
              >
              </fin-select-input>

              <fin-select-input
                data-testid="form-field-yearOfBirth"
                class="flex-1 flex--basis0"
                [controlName]="'yearOfBirth'"
                [parentForm]="basicInfoFormBirthDate"
                [options]="availableYears$ | async"
                [displayMargin]="false"
                [disableLabel]="true"
                [translationPrefix]="'login.join.fields.yearOfBirth'"
                [selectLabelTranslationKey]="'login.join.yearSelect'"
              >
              </fin-select-input>
            </div>
          </fin-form-group>

          <span *ngIf="isJunior && basicInfoFormBirthDate.valid" class="font-body-2 regular-type nordic-blue-400-text">
            {{ 'login.join.joinJunior' | finLocalization }}
          </span>

          <div class="ms-medium-b ps-xxsmall-t">
            <fcom-radio-item-group
              [options]="[{ value: LoginGender.MALE }, { value: LoginGender.FEMALE }]"
              [controlName]="'gender'"
              [isSensitive]="true"
              [translationPrefix]="'login.join.fields.gender'"
              [parentForm]="basicInfoForm"
            >
            </fcom-radio-item-group>
          </div>

          <ng-container
            [ngTemplateOutlet]="continueBtn"
            [ngTemplateOutletContext]="{ disabled: !basicInfoForm.valid }"
          />
        </form>

        <button
          *ngIf="fullScreen"
          type="button"
          class="underline nordic-blue-900-text fill align-center ms-small-y tr-medium font-body-1"
          (click)="openLogin()"
        >
          {{ 'login.join.logIn' | finLocalization }}
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="JoinFlowStep.CONTACT_DETAILS">
        <form
          (submit)="moveToStep(isJunior ? JoinFlowStep.GUARDIAN_DETAILS : JoinFlowStep.MARKETING_CONSENT)"
          [formGroup]="contactDetailsForm"
        >
          <fin-form-group
            class="block ps-small-b"
            [group]="contactDetailsFormPhone"
            [translationPrefix]="'login.join.fields.phone'"
            [required]="false"
            [showOnlyGroupLevelErrors]="true"
            [names]="['phonePrefix', 'phoneNumber']"
          >
            <span
              class="font-body-1 regular-type light-type nordic-blue-400-text"
              [attr.id]="'select-group-label-phone-number'"
            >
              {{ 'login.join.fields.phone.label' | finLocalization }}
            </span>

            <div class="flex">
              <fin-select-input
                class="ms-xxsmall-r flex--basis2"
                [parentForm]="contactDetailsFormPhone"
                [controlName]="'phonePrefix'"
                [options]="phonePrefixes$ | async"
                [displayMargin]="false"
                [disableSelectOption]="true"
                [disableLabel]="true"
              ></fin-select-input>
              <fcom-text-input
                [class.fill]="fullScreen"
                [parentForm]="contactDetailsFormPhone"
                [displayMargin]="false"
                [hideLabel]="true"
                [controlName]="'phoneNumber'"
                (inputEvent)="removeLeadingZero($event)"
              ></fcom-text-input>
            </div>
          </fin-form-group>
          <fcom-text-input
            class="block ps-small-b"
            [translationPrefix]="'login.join.fields.email'"
            [parentForm]="contactDetailsForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'email'"
            (change)="email$.next(contactDetailsForm.get('email').value)"
          ></fcom-text-input>

          <fin-combo-box
            [id]="'select-country'"
            [parentForm]="contactDetailsForm"
            [controlName]="'countryCode'"
            [required]="false"
            [searchable]="true"
            [options]="countryOptions | async"
            [displayMargin]="false"
            [translationPrefix]="'login.join.fields.country'"
            [clearSearchLabel]="'login.join.fields.country.clearSearch' | finLocalization"
            [tooltipHeading]="'login.join.fields.country.tooltipHeading' | finLocalization"
            [tooltipContent]="'login.join.fields.country.tooltipContent' | finLocalization"
            [tooltipOptions]="{ zIndex: 6 }"
            class="block ps-small-b"
          ></fin-combo-box>

          <fin-form-group
            class="block"
            [groupTranslationPrefix]="'login.join.fields.confirmPassword'"
            [group]="contactDetailsFormPassword"
            [names]="['pwd', 'confirmPwd']"
          >
            <fcom-text-input
              class="block ps-small-b"
              [translationPrefix]="'login.join.fields.password'"
              [parentForm]="contactDetailsFormPassword"
              [displayMargin]="false"
              [required]="false"
              [controlName]="'pwd'"
              [isPassword]="true"
            ></fcom-text-input>
            <fcom-text-input
              [translationPrefix]="'login.join.fields.confirmPassword'"
              [parentForm]="contactDetailsFormPassword"
              [displayMargin]="false"
              [required]="false"
              [isPassword]="true"
              [controlName]="'confirmPwd'"
            ></fcom-text-input>
          </fin-form-group>

          <ng-container
            *ngIf="!isJunior"
            [ngTemplateOutlet]="termsAndPolicy"
            [ngTemplateOutletContext]="{
              formGroup: contactDetailsForm,
              control: 'accept',
              contentUrl: 'fragments.acceptProgramRules.url',
            }"
          />

          <ng-container
            [ngTemplateOutlet]="continueBtn"
            [ngTemplateOutletContext]="{
              disabled: !(contactDetailsForm.valid && (isJunior || contactDetailsForm.get('accept').value)),
            }"
          />
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="JoinFlowStep.GUARDIAN_DETAILS">
        <form
          (submit)="enableAccountCreationVerification ? moveToStep(JoinFlowStep.EMAIL_VEFIFICATION) : complete(true)"
          [formGroup]="guardianInfoForm"
        >
          <fcom-text-input
            [translationPrefix]="'login.join.fields.guardianName'"
            [parentForm]="guardianInfoForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'guardianName'"
          ></fcom-text-input>

          <fin-form-group
            class="block ps-small-y"
            [group]="guardianInfoFormPhone"
            [translationPrefix]="'login.join.fields.guardianPhone'"
            [required]="false"
            [showOnlyGroupLevelErrors]="true"
            [names]="['phonePrefix', 'phoneNumber']"
          >
            <span
              class="font-body-1 regular-type light-type nordic-blue-400-text"
              [attr.id]="'select-group-label-guardian-phone-number'"
            >
              {{ 'login.join.fields.guardianPhone.label' | finLocalization }}
            </span>

            <div class="flex">
              <fin-select-input
                class="ms-xxsmall-r flex--basis2"
                [parentForm]="guardianInfoFormPhone"
                [controlName]="'phonePrefix'"
                [options]="phonePrefixes$ | async"
                [displayMargin]="false"
                [disableSelectOption]="true"
                [disableLabel]="true"
              ></fin-select-input>
              <fcom-text-input
                [class.fill]="fullScreen"
                [parentForm]="guardianInfoFormPhone"
                [displayMargin]="false"
                [hideLabel]="true"
                [controlName]="'phoneNumber'"
              ></fcom-text-input>
            </div>
          </fin-form-group>

          <fcom-text-input
            [translationPrefix]="'login.join.fields.guardianEmail'"
            [parentForm]="guardianInfoForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'guardianEmail'"
          ></fcom-text-input>

          <fcom-text-input
            class="ps-small-t block"
            [translationPrefix]="'login.join.fields.guardianMemberNumber'"
            [parentForm]="guardianInfoForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'guardianMemberNumber'"
          ></fcom-text-input>

          <ng-container
            [ngTemplateOutlet]="termsAndPolicy"
            [ngTemplateOutletContext]="{
              formGroup: guardianInfoForm,
              control: 'consent',
              contentUrl: 'fragments.guardianProgramRules.url',
            }"
          />

          <ng-container
            [ngTemplateOutlet]="continueBtn"
            [ngTemplateOutletContext]="{
              disabled: !guardianInfoForm.get('consent').value || !guardianInfoForm.valid,
              loading: (loading$ | async),
              label: 'login.join.joinFinnairPlus',
            }"
          />
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="JoinFlowStep.MARKETING_CONSENT">
        <div aria-live="assertive">
          <fcom-notification
            *ngIf="(apiStatus$ | async) === ApiStatus.JOIN_ERROR"
            class="mr-xsmall-t"
            [theme]="NotificationTheme.ALERT"
          >
            <span class="font-body-1">{{ 'login.join.flowError' | finLocalization }}</span>
          </fcom-notification>
        </div>

        <form
          (submit)="complete()"
          [formGroup]="marketingConsentForm"
          *ngIf="consentTextsGroup$ | async as consentGroup"
        >
          <p class="ps-medium-y">{{ consentGroup.introductionText | finStripHtml }}</p>

          <fcom-checkbox
            *ngFor="let consent of consentGroup.consentTexts; let first = first; let last = last"
            [formControlName]="consent.consentTextId"
            [formGroup]="marketingConsentForm"
            [ngClass]="{
              block: true,
              'ps-medium-t': first,
              'ps-large-b': !last,
              'ps-xxsmall-b': last,
            }"
          >
            <div>{{ consent.text | finStripHtml }}</div>
          </fcom-checkbox>

          <p class="font-body-1 ms-large-t regular-type" [innerHTML]="consentGroup.afterwordText | finSafe: 'html'"></p>
          <ng-container
            [ngTemplateOutlet]="continueBtn"
            [ngTemplateOutletContext]="{ label: 'login.join.joinFinnairPlus', loading: (loading$ | async) }"
          />
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="JoinFlowStep.EMAIL_VEFIFICATION">
        <div aria-live="assertive">
          <fcom-notification
            *ngIf="(apiStatus$ | async) === ApiStatus.RESEND_SUCCESS"
            class="block ms-medium-t"
            [theme]="NotificationTheme.SUCCESS"
            data-testid="resend-success"
          >
            {{ 'login.join.resendSuccess' | finLocalization }}
          </fcom-notification>
          <fcom-notification
            *ngIf="(apiStatus$ | async) === ApiStatus.RESEND_ERROR"
            class="mr-xsmall-t"
            [theme]="NotificationTheme.ALERT"
          >
            <span class="font-body-1">{{ 'login.join.resendError' | finLocalization }}</span>
          </fcom-notification>
        </div>

        <p class="font-body-1 regular-type ms-small-y" [innerHTML]="verificationEmailSentText$ | async"></p>
        <p
          class="font-body-1 regular-type ms-small-y"
          [innerHTML]="'login.join.verificationGoToInbox' | finLocalization | finSafe: 'html'"
        ></p>
        <p class="font-body-1 regular-type ms-small-y">
          {{ 'login.join.verificationDidntReceive' | finLocalization }}
          &nbsp;
          <fcom-link
            class="font-body-1 regular-type bold underline nordic-blue-900-text"
            (afterNavigation)="resend()"
            data-testid="resend-link"
          >
            {{ 'actions.resend' | finLocalization }}</fcom-link
          >
        </p>
        <fcom-notification
          *ngIf="resendLimitReached$ | async"
          class="error block ms-medium-t"
          [iconName]="SvgLibraryIcon.ALERT_TRIANGLE"
          [theme]="NotificationTheme.WARNING"
          data-testid="resend-alert"
        >
          {{ 'login.join.verificationWarning' | finLocalization }}
        </fcom-notification>
      </ng-container>

      <ng-container *ngSwitchCase="JoinFlowStep.JOIN_COMPLETE">
        <p class="font-body-1 regular-type">
          {{ 'login.join.completedEmailSent' | finLocalization: { email: contactDetailsForm.controls['email'].value } }}
        </p>

        <ng-container
          [ngTemplateOutlet]="continueBtn"
          [ngTemplateOutletContext]="{
            label: 'login.join.logIn',
            icon: { name: SvgLibraryIcon.SINGLE_PAX, position: IconPosition.LEFT },
            login: true,
          }"
        />

        <fcom-button
          data-testid="join-form-read-more"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [mode]="ButtonMode.FILL"
          (clickBtn)="readMore()"
          [text]="'login.join.readMore' | finLocalization"
        ></fcom-button>
      </ng-container>
    </ng-container>
  </fin-login-form-container>
</ng-container>

<ng-template
  #continueBtn
  let-disabled="disabled"
  let-label="label"
  let-icon="icon"
  let-login="login"
  let-loading="loading"
>
  <fcom-button
    [type]="ButtonType.SUBMIT"
    [aria]="{ label: label ?? 'actions.continue' | finLocalization, loadingLabel: 'actions.loading' | finLocalization }"
    [disabled]="disabled"
    [loading]="loading"
    (clickBtn)="login ? openLogin() : undefined"
    [icon]="icon ? icon : { name: SvgLibraryIcon.ARROW_RIGHT, position: IconPosition.RIGHT }"
    [mode]="ButtonMode.FILL"
    [text]="label ?? 'actions.continue' | finLocalization"
    class="ms-large-t ms-small-b"
  ></fcom-button>
</ng-template>

<ng-template #termsAndPolicy let-contentUrl="contentUrl" let-formGroup="formGroup" let-control="control">
  <div [formGroup]="formGroup" class="block ps-large-y">
    <fcom-checkbox [name]="control" [formControlName]="control" [cid]="control">
      <cms-unstyled-fragment class="block font-body-1" [contentUrl$]="contentUrl | finLocalization | finToObservable">
      </cms-unstyled-fragment>
    </fcom-checkbox>
  </div>

  <cms-unstyled-fragment
    class="block font-body-1"
    [contentUrl$]="'fragments.privacyPolicy.url' | finLocalization | finToObservable"
  >
  </cms-unstyled-fragment>
</ng-template>
