<div class="chatBody">
  <div class="ps-medium" #formContainer>
    <form
      class="flex flex--column"
      [formGroup]="reactiveForm"
      [finFormErrorFocus]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <p>{{ 'chatUserForm.intro' | finLocalization }}</p>

      <div class="flex flex--column input-fields">
        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.FIRST_NAME"
          [translationPrefix]="'chatUserForm.firstName'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.LAST_NAME"
          [translationPrefix]="'chatUserForm.lastName'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.EMAIL"
          [translationPrefix]="'chatUserForm.emailAddress'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.SUBJECT"
          [translationPrefix]="'chatUserForm.subject'"
          [placeHolder]="'chatUserForm.subject.placeholder'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.BOOKING_REFERENCE"
          [translationPrefix]="'chatUserForm.bookingReference'"
          [placeHolder]="'chatUserForm.bookingReference.placeholder'"
          [displayMargin]="false"
        ></fcom-text-input>
      </div>

      <fcom-button
        [type]="ButtonType.SUBMIT"
        [text]="'chatUserForm.submitText' | finLocalization"
        [mode]="ButtonMode.FILL"
        [size]="ButtonSize.LARGE"
        [loading]="loading$ | async"
      ></fcom-button>
    </form>
  </div>
</div>
