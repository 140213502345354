import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { AppState } from '@fcom/core';
import { loginStatus, profile, profileType } from '@fcom/core/selectors';
import { Profile, ProfileType } from '@fcom/core-api/login';
import { finShare } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

import { PrefillProfileData } from '../interfaces';

@Injectable()
export class PrefillService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(private store$: Store<AppState>) {}

  prefillFromProfile(
    form: UntypedFormGroup,
    formFields: { [key: string]: AbstractControl }
  ): Observable<{ [key: string]: boolean }> {
    return new Observable<{ [key: string]: boolean }>((observer) => {
      this.subscriptions.add(
        combineLatest([
          this.store$.pipe(
            loginStatus(),
            finShare(),
            filter((status) => status === 'LOGGED_IN'),
            switchMap(() => this.store$.pipe(profile(), take(1)))
          ),
          this.store$.pipe(profileType(), take(2)),
        ]).subscribe(([memberProfile, memberProfileType]) => {
          this.setValues(memberProfile, formFields, memberProfileType);
          form.updateValueAndValidity();
          observer.next({ prefilled: true });
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  private setValues(
    memberProfile: Profile,
    formFields: { [key: string]: AbstractControl },
    memberProfileType: ProfileType
  ) {
    Object.keys(formFields).forEach((field) => {
      const formField = formFields[field];

      let value = memberProfile[field] || this.getFieldFromPrimaryAddress(memberProfile, field);

      if (field === PrefillProfileData.MEMBER_NUMBER && memberProfileType !== ProfileType.FPLUS) {
        return;
      }

      if (value || field === PrefillProfileData.FIRST_LAST_NAME) {
        switch (field) {
          case PrefillProfileData.MEMBER_NUMBER:
            value = memberProfile.memberNumber;
            break;
          case PrefillProfileData.PARSED_MOBILE_PHONE:
            value = value.countryCode && value.nationalNumber ? `+${value.countryCode}${value.nationalNumber}` : '';
            break;
          case PrefillProfileData.LANGUAGE:
            console.log('Language', value);
            value = value.toUpperCase();
            break;
          case PrefillProfileData.FIRST_LAST_NAME:
            value = `${memberProfile.firstname} ${memberProfile.lastname}`;
            break;
          default:
        }
        formField.setValue(value);
      }
    });
  }

  private getFieldFromPrimaryAddress(memberProfile: Profile, field: string) {
    const primaryAddress = memberProfile.addresses?.find((address) => address.primary);
    if (primaryAddress) {
      return primaryAddress[field];
    }
  }
}
