import { Component, Input, OnInit } from '@angular/core';

import { NEVER, Observable, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsCollection, CmsOffer } from '@fcom/core-api';
import {
  PageMetaService,
  PriceCalendarCTAParams,
  PriceCalendarParams,
  PriceCalendarService,
  PromotionItem,
  PromotionType,
} from '@fcom/common';
import { GlobalBookingTravelClass, TripType } from '@fcom/core/interfaces';
import { GtmService } from '@fcom/common/gtm';
import { getOfferWithAmDestination } from '@fcom/common/utils';
import { AmDestinationService } from '@fcom/destination-search/services/am-destination.service';
import { LocationRouteCffService, stopPropagation } from '@fcom/core';

import { BlockOffer, BlockOfferData } from '../../interfaces';
import { toPromoBlock } from '../../services';

@Component({
  selector: 'cms-offers-grid',
  template: `
    <fin-offer-grid
      *ngIf="originLocationCode$ | async as originLocationCode"
      [title]="content.teaserTitle"
      [offers$]="offers$"
      [cmsTags]="content.subjectTaxonomyTags || []"
      (flightHotelClicked)="openAmContinueModal($event)"
      (calendarClicked)="calendarClicked(originLocationCode, $event)"
      [tripType]="tripType"
    ></fin-offer-grid>
    <fin-am-destination-continue-modal
      [open]="isAmContinueModalOpen"
      (closeModal)="closeAmContinueModal()"
      [bookUrl]="flightHotelUrl"
    ></fin-am-destination-continue-modal>
    <fcom-modal
      [open]="(calendarOpen$ | async) !== null"
      [fitToContent]="true"
      [buttons]="null"
      [roundedContent]="true"
      [fullHeight]="true"
      [extraWide]="true"
      (close)="closePriceCalendar()"
      data-testid="price-calendar-modal"
    >
      <fin-price-calendar
        [openWithParams$]="calendarOpen$"
        [showAddReturn]="true"
        [showSubtitle]="true"
        [ctaLabel]="'bookingSearch.search' | finLocalization"
        [resetCalender]="true"
        (modalClose)="closePriceCalendar()"
        (ctaClicked)="priceCalendarCTAClicked($event)"
      ></fin-price-calendar>
    </fcom-modal>
  `,
})
export class CmsOffersGridComponent implements OnInit {
  @Input()
  content: CmsCollection;

  offers$: Observable<BlockOfferData[]>;
  tripType: TripType;
  flightHotelUrl: string;
  isAmContinueModalOpen = false;
  calendarOpen$: Observable<PriceCalendarParams | null> = NEVER;
  originLocationCode$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private gtmService: GtmService,
    private amDestinationService: AmDestinationService,
    private locationRouteCffService: LocationRouteCffService,
    private priceCalendarService: PriceCalendarService,
    private pageMetaService: PageMetaService
  ) {
    this.calendarOpen$ = this.priceCalendarService.calendarOpen$;
    this.originLocationCode$ = this.pageMetaService.defaultOriginLocationCode$;
  }

  ngOnInit(): void {
    this.offers$ = this.languageService.lang.pipe(
      map((lang) => this.content.items.map((offer) => toPromoBlock(offer as CmsOffer, lang))),
      switchMap((offers) =>
        this.languageService.localeValue.includes('FI')
          ? getOfferWithAmDestination(
              offers,
              this.amDestinationService.amDestinationList(),
              this.locationRouteCffService
            )
          : of(offers)
      ),
      tap((offers) => {
        const promotionItems: PromotionItem[] = offers.map((offer, index) => ({
          position: `${index}`,
          id: 'top-destinations',
          product: offer.destination,
          type: 'static',
        }));

        this.gtmService.internalPromotion(promotionItems, PromotionType.VIEW);
      })
    );

    this.tripType = TripType[this.content.localSettings?.tripType?.toUpperCase()] ?? TripType.RETURN;
  }

  openAmContinueModal(flightHotelUrl): void {
    this.isAmContinueModalOpen = true;
    this.flightHotelUrl = flightHotelUrl;
  }

  closeAmContinueModal(): void {
    this.isAmContinueModalOpen = false;
  }

  calendarClicked(
    selectedOriginLocationCode: string,
    [event, destinationItem, travelClass, tripType]: [Event, BlockOffer, GlobalBookingTravelClass, TripType]
  ): void {
    stopPropagation(event);
    this.priceCalendarService.openPriceCalendar(
      selectedOriginLocationCode,
      destinationItem.destination,
      tripType,
      travelClass,
      destinationItem.title
    );
  }

  priceCalendarCTAClicked(params: PriceCalendarCTAParams): void {
    this.priceCalendarService.priceCalendarCTAClicked(params);
  }

  closePriceCalendar(): void {
    this.priceCalendarService.closePriceCalendar();
  }
}
