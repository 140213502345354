import { InjectionToken } from '@angular/core';

export enum TestVariant {
  NOT_IN_TEST = 'NOT_IN_TEST',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
}

export const akamaiSegmentToNumericMap = {
  '30e0247b34712ccb': 0,
  '814a48718cc39747': 1,
  '9536f017b1cc3522': 2,
  '45b7ce8935901775': 3,
  '7b0b9ab7e196acc': 4,
};

export enum MultivariateTestId {
  PROMOTED_ANCILLARIES_LIST = 'travel-extras/2025/promoted-ancillaries-list/trello-12709',
  RELEVANT_OFFERS = 'front-page/2025/europe-under-100/trello-12639',
  START_SEARCH_WITH_LESS_CLICKS = 'global-widget/2025/start-search-with-less-clicks/trello-12521',
  ENHANCED_CALENDAR_STYLING = 'booking-widget/2025/calendar-stylings-and-scroll/trello-12721',
  ANCILLARY_CAMPAIGN_PROMOTIONS = 'travel-extras/2025/campaign-promotions/trello-12832',
}

export interface TestVariantWithId {
  variant: TestVariant;
  id: MultivariateTestId;
}

export type Segment = 0 | 1 | 2 | 3 | 4;

export interface UserVariantSegment {
  segment: Segment;
  source: VariantSource;
}

export type SegmentResolver = (segment: Segment) => TestVariant;

export enum VariantSource {
  AKAMAI = 'akamai',
  RANDOMIZED = 'randomized',
  MEMBER = 'member',
  MANUAL = 'manual',
}

type MultivariateTestSegmentConfig = Partial<{ [K in TestVariant]: Segment[] }>;

export interface MultivariateTestConfiguration {
  id: MultivariateTestId;
  /**
   * Do we need to inject segment to html body before the Angular app is bootstrapped on client side
   * When this is flagged we need to have both variant somehow displayed at the same time with css
   */
  requiresInjectScript: boolean;
  segments: MultivariateTestSegmentConfig;
  includeCountries?: string[];
}

export const MANUAL_AB_SEGMENT_COOKIE = 'manual_ab_segment';
export const AKAMAI_AB_SEGMENT_COOKIE = 'akamai_ab_segment';

export interface MultivariateTestCookies {
  [MANUAL_AB_SEGMENT_COOKIE]: string;
  [AKAMAI_AB_SEGMENT_COOKIE]: string;
}

export const MULTIVARIATE_TEST_COOKIES: InjectionToken<MultivariateTestCookies> = new InjectionToken(
  'MultivariateTestCookies'
);
