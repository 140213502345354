import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import {
  FinnairBoundItem,
  FinnairDisruptedBoundItem,
  FinnairItineraryItemFlight,
  FinnairLocation,
} from '@fcom/dapi/api/models';
import { LanguageService } from '@fcom/ui-translate';
import { COMMON_BOOKING_TRAVEL_CLASSES, ConfigService } from '@fcom/core';
import { Airport } from '@fcom/common/interfaces/booking';
import { TagTheme } from '@fcom/ui-components/components/tag/interfaces';
import { ButtonTheme, ButtonSize, ButtonMode } from '@fcom/ui-components/components/buttons/button/enums';

import {
  isBound,
  isByBusOnly,
  isFlight,
  isUsingSAF,
  legEndpointToUfoAirport,
  operatingAirlineCodeOrBusIndicator,
} from '../../utils/common-booking.utils';
import { FinnairBoundItemWithLocation } from '../../interfaces';

@Component({
  selector: 'fin-ufo-with-modify-button',
  styleUrls: ['./ufo-with-modify-button.component.scss'],
  templateUrl: './ufo-with-modify-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UFOWithModifyButtonComponent implements OnInit, OnChanges {
  readonly TagTheme = TagTheme;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;

  @Input()
  flightOrBound:
    | FinnairBoundItemWithLocation
    | FinnairBoundItem
    | FinnairDisruptedBoundItem
    | FinnairItineraryItemFlight;
  @Input()
  locations?: { [key: string]: FinnairLocation };
  @Input()
  title: string;
  @Input()
  viewDetailsClosed: string;
  @Input()
  hideModify = false;
  @Input()
  hideViewDetails = false;
  // Parent component passes down info if the component is used in booking flow or not
  // We want to show the sustainability feature in booking flow, but not in cancel or reacc flows
  @Input() paxReviewCard = false;

  @Output()
  viewDetails: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  modifyClicked: EventEmitter<void> = new EventEmitter<void>();

  ufoAirports$: Observable<{ departure: Airport; arrival: Airport }>;
  tails: string[];
  isByBusOnly: boolean;
  isFlightUsingSAF: boolean;
  stops: number;
  fareFamily: string;
  cabinClass: string;
  uniqueAirlineNames: string[];
  flightNumbers: string;

  flightOrBound$: ReplaySubject<
    FinnairBoundItemWithLocation | FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight
  > = new ReplaySubject<
    FinnairBoundItemWithLocation | FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight
  >(1);
  locations$: ReplaySubject<{ [key: string]: FinnairLocation }> = new ReplaySubject<{ [key: string]: FinnairLocation }>(
    1
  );
  enableShowingSAF: boolean;

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.enableShowingSAF = this.configService.cfg.enableShowingSAF;
    this.ufoAirports$ = combineLatest([
      this.languageService.translate('date'),
      this.flightOrBound$,
      this.locations$.pipe(startWith(undefined)),
    ]).pipe(
      map(([translations, flightOrBound, locations]) => ({
        departure: legEndpointToUfoAirport(flightOrBound?.departure, translations, locations),
        arrival: legEndpointToUfoAirport(flightOrBound?.arrival, translations, locations),
      }))
    );
    this.locations$.next(this.locations);
    this.updateFlightOrBound();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locations']) {
      this.locations$.next(this.locations);
    }

    if (changes['flightOrBound']) {
      this.updateFlightOrBound();
    }
  }

  private updateFlightOrBound(): void {
    this.flightOrBound$.next(this.flightOrBound);

    if (isBound(this.flightOrBound)) {
      this.uniqueAirlineNames = this.flightOrBound.uniqueAirlineNames;
      this.stops = this.flightOrBound.stops;
      this.fareFamily = this.flightOrBound.fareFamily?.name;

      if (!this.fareFamily) {
        this.cabinClass = COMMON_BOOKING_TRAVEL_CLASSES[this.flightOrBound['cabinClass']]?.translationKey;
      }
      this.tails = this.flightOrBound.itinerary
        .filter(isFlight)
        .map((flight) => operatingAirlineCodeOrBusIndicator(flight));

      this.flightNumbers = this.flightOrBound?.itinerary
        .map((flight: FinnairItineraryItemFlight) => flight?.flightNumber)
        .filter(Boolean)
        .join(', ');
    } else {
      this.uniqueAirlineNames = [this.flightOrBound?.operatingAirline?.name];
      this.stops = 0;
      this.cabinClass = COMMON_BOOKING_TRAVEL_CLASSES[this.flightOrBound.cabinClass].translationKey;
      this.tails = [operatingAirlineCodeOrBusIndicator(this.flightOrBound)];
      this.flightNumbers = this.flightOrBound?.flightNumber;
    }

    this.isByBusOnly = isByBusOnly(this.flightOrBound);

    this.isFlightUsingSAF = isUsingSAF(this.flightOrBound);
  }
}
